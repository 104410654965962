<template>
  <v-card class="pa-4">
    <!-- dialog alert -->
    <v-dialog v-model="dialogAlert" width="500">
      <v-card>
        <v-card-title color="error">
          {{ $t('warning') }}
        </v-card-title>

        <v-card-text>
          {{ alertText }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogAlert = false">
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog add instance -->
    <v-dialog v-model="dialogAddInstance" width="600px">
      <v-card>
        <!-- <v-card-actions>
          <h3 class="font-weight-bold">{{ $t('add') }} {{ $t('channels.channel') }}</h3>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3" @click="closeDialogAddTokped">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn v-if="currentTab2 === 1" color="success" class="" @click="saveTokopediaAdd">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions> -->
        <v-divider />

        <!-- <v-card-text> -->
        <v-tabs center-active>
          <!-- <v-tab v-for="(column, index) in columns" :key="index + 'columns'">
            {{ column }}
          </v-tab> -->
          <v-tab>
            {{ columns[currentTab] }}
          </v-tab>
        </v-tabs>
        <!-- Whatsapp cloud account -->
        <v-card v-if="currentTab2 === 3">
          <v-card-actions>
            <h3 class="font-weight-bold">{{ $t('add') }} {{ $t('channels.channe l') }}</h3>
            <v-spacer></v-spacer>
            <v-btn color="success" class="hide-buton" @click="saveWaCloud">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
          <v-divider />
          <v-card-text>
            <v-text-field v-model="waCloudPayload.businessName" outlined dense :label="`Business Name`"></v-text-field>
            <v-text-field v-model="waCloudPayload.token" outlined dense :label="`Access Token`"></v-text-field>
            <v-text-field
              v-model="waCloudPayload.businessId"
              outlined
              dense
              :label="`Whatsapp Business Account ID`"
            ></v-text-field>
            <v-text-field v-model="waCloudPayload.phone_number" outlined dense :label="`Phone Number`"></v-text-field>
            <v-text-field
              v-model="waCloudPayload.phone_number_id"
              outlined
              dense
              :label="`Phone Number ID`"
            ></v-text-field>
          </v-card-text>
          <v-divider class="bottom-action" />
          <v-card-actions class="bottom-action text-end justify-end">
            <v-btn color="#ffeeee" class="mr-3" @click="closeDialogAddTokped">
              <span class="error--text">{{ $t('cancel') }}</span>
            </v-btn>
            <v-btn color="success" class="" @click="saveTokopediaAdd">
              {{ $t('save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- Twitter account -->
        <v-card-text v-if="currentTab2 === 2" class="mt-6">
          <div class="mx-auto mt-2 text-center">
            <v-btn class="mt-2" color="primary" @click="handleTwitterLogin"> Connect your twitter account </v-btn>
          </div>
        </v-card-text>
        <!-- <v-card-text v-if="currentTab2 === 3" class="mt-6">
          <div class="mx-auto mt-2 text-center">
            <v-btn class="mt-2" color="primary" @click="connectLazadaAccount">
              Connect Your Lazada Seller Account
            </v-btn>
          </div>
        </v-card-text> -->

        <!-- Facebook account -->
        <v-card-text v-if="currentTab2 === 1" class="mt-6">
          <div class="mx-auto mt-2 text-center">
            <v-btn class="mt-2" color="primary" @click="logInWithFacebook"> Login Facebook & add Page </v-btn>
          </div>
        </v-card-text>
        <!-- Instagram account -->
        <v-card-text v-if="currentTab2 === 4" class="mt-6">
          <!-- <div class="mx-auto mt-2 text-center">
            <v-btn
              class="mt-2"
              color="primary"
              @click="handleConnectInstagram"
            >
              Connect your instagram account
            </v-btn>
          </div> -->
          <div class="instagram-container">
            <v-list>
              <div v-for="item in listInstagramInstance" :key="item._id" class="instagram-row-container">
                <div class="">
                  <div>{{ item.page_name }}</div>
                  <div>Available to Connect</div>
                </div>
                <v-btn v-if="isInstagramConnected(item)" color="primary" @click="handleConnectInstagram(item)">
                  Connect
                </v-btn>
                <v-btn v-else color="primary" disabled> Connected </v-btn>
              </div>
            </v-list>
          </div>
        </v-card-text>
        <!-- Sosial media account -->
        <v-card-text v-if="currentTab2 === 5" class="mt-6">
          <div class="mx-auto mt-2 text-center">
            <v-btn v-if="activeUserAyrshare === false" class="mt-2" color="primary" @click="addSosialMediaInstance">
              Connect your social media account
            </v-btn>
            <v-btn
              v-if="activeUserAyrshare !== false"
              class="mt-2"
              color="primary"
              @click="generateJWT(activeUserAyrshare.profileKey)"
            >
              Link to social media
            </v-btn>
          </div>
        </v-card-text>
        <!-- <v-card-text v-if="currentTab2 === 1" class="mt-5">
          <v-text-field
            v-model="tokopediaAddPayload.clientSecret"
            outlined
            dense
            :label="`Client Secret`"
          ></v-text-field>
          <v-text-field v-model="tokopediaAddPayload.clientId" outlined dense :label="`Client ID`"></v-text-field>
          <v-text-field
            v-model="tokopediaAddPayload.fs_id"
            outlined
            dense
            :label="`FS ID or Application ID`"
          ></v-text-field>
          <v-text-field v-model="tokopediaAddPayload.shop_id" outlined dense :label="`Shop ID`"></v-text-field>
        </v-card-text> -->

        <!-- Whatsapp account -->
        <v-row v-if="currentTab2 === 0" class="pa-4">
          <v-col cols="auto">
            <!-- {{ qrCode }} -->
            <div v-if="(!qrCode || loadQr) && createLocalInstance.status !== 1" class="text-center loading-box">
              <v-progress-circular size="128" class="mt-15" color="primary" indeterminate></v-progress-circular>
            </div>
            <div v-if="!loadQr && createLocalInstance.status !== 1">
              <v-img id="qr-code" :src="qrCode"> </v-img>
              <!-- <iframe
                id="iframe-qr-code"
                scrolling="no"
                class="loading-iframe"
                title="QR Code"
                width="266"
                height="267"
                style="border: 0"
                :src="getIframeSrc(createLocalInstance.sub_id + '-' + createLocalInstance.instance_id)"
              ></iframe> -->
            </div>
            <div
              v-if="
                !createLocalInstance.is_adding && (createLocalInstance.status == 1 || createLocalInstance.status == 4)
              "
            >
              <img height="260" width="284" src="@/assets/images/phone_connected_md.png" />
            </div>
          </v-col>
          <v-col>
            <span class="font-weight-bold">{{ $t('channels.howToUse') }}</span>
            <br />
            <span v-html="howToUseOne"></span>
            <br />
            <span v-html="howToUseTwo"></span>
            <br />
            <span v-html="howToUseThree"></span>
            <br />
            <span v-html="howToUseFour"></span>
            <br />
            <span v-html="howToUseFive"></span>
            <br />
            <span v-html="howToUseSix"></span>

            <div class="text-center mt-16">
              <v-chip v-if="createLocalInstance.status == 0" color="#fbb4bb">
                {{ $t('channels.notConnected') }}
              </v-chip>
              <v-chip v-if="createLocalInstance.status == 1" color="#2da583">
                {{ $t('channels.connected') }}
              </v-chip>
              <v-chip v-if="createLocalInstance.status == 2" color="#FF4C51">
                {{ $t('channels.disconnected') }}
              </v-chip>
              <v-chip v-if="createLocalInstance.status == 3" color="#FF4C51">
                {{ $t('channels.disconnected') }}
              </v-chip>
              <v-chip v-if="createLocalInstance.status == 4" color="warning">
                {{ $t('channels.preparing') }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
        <!-- </v-card-text> -->

        <v-snackbar v-model="errorNewChatEmpty" color="error" :top="true" style="z-index: 1 !important">
          {{ joinedError }}
          <template #action="{ attrs }">
            <v-btn text v-bind="attrs" @click="closeErrorNewChatSnackbar">
              {{ $t('close') }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </v-dialog>

    <!-- dialog add instance Tokopedia -->
    <v-dialog v-model="dialogAddInstanceTokped" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ $t('add') }} {{ $t('channels.channel') }}</h3>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3 hide-buton" @click="closeDialogAddTokped">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="hide-buton" @click="saveTokopediaAdd">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-text-field
            v-model="tokopediaAddPayload.clientSecret"
            outlined
            dense
            :label="`Client Secret`"
          ></v-text-field>
          <v-text-field v-model="tokopediaAddPayload.clientId" outlined dense :label="`Client ID`"></v-text-field>
          <v-text-field
            v-model="tokopediaAddPayload.fs_id"
            outlined
            dense
            :label="`FS ID or Application ID`"
          ></v-text-field>
          <v-text-field v-model="tokopediaAddPayload.shop_id" outlined dense :label="`Shop ID`"></v-text-field>
        </v-card-text>
        <v-divider class="bottom-action" />
        <v-card-actions class="bottom-action text-end justify-end">
          <v-btn color="#ffeeee" class="mr-3" @click="closeDialogAddTokped">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="saveTokopediaAdd">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog edit instance Tokopedia -->
    <v-dialog v-model="dialogEditInstanceTokped" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ $t('edit') }} {{ $t('channels.channel') }}</h3>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3 hide-buton" @click="closeDialogEditTokped">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="hide-buton" @click="saveTokopediaEdit">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-text-field v-model="tokopediaEditPayload.label" outlined dense :label="`Shop Name`"></v-text-field>
          <v-text-field
            v-model="tokopediaEditPayload.clientSecret"
            outlined
            dense
            :label="`Client Secret`"
          ></v-text-field>
          <v-text-field v-model="tokopediaEditPayload.clientId" outlined dense :label="`Client ID`"></v-text-field>
          <v-text-field
            v-model="tokopediaEditPayload.fs_id"
            outlined
            dense
            :label="`FS ID or Application ID`"
          ></v-text-field>
          <v-text-field v-model="tokopediaEditPayload.shop_id" outlined dense :label="`Shop ID`"></v-text-field>
          <span>{{ $t('channels.color') }}</span>
          <v-color-picker
            v-model="tokopediaEditPayload.color"
            dot-size="25"
            hide-mode-switch
            mode="hexa"
            swatches-max-height="200"
          ></v-color-picker>
        </v-card-text>
        <v-divider class="bottom-action" />
        <v-card-actions class="bottom-action text-end justify-end">
          <v-btn color="#ffeeee" class="mr-3" @click="closeDialogEditTokped">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="saveTokopediaEdit">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog edit instance WA -->
    <v-dialog v-model="dialogEditInstances" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ $t('edit') }} {{ $t('channels.channel') }}</h3>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3 hide-buton" @click="dialogEditInstances = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="hide-buton" @click="saveInstancesEdit">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          <v-text-field v-model="instanceName" outlined dense :label="$t('channels.label')"></v-text-field>
          <span>{{ $t('channels.color') }}</span>
          <v-color-picker
            v-model="instanceColor"
            dot-size="25"
            hide-mode-switch
            mode="hexa"
            swatches-max-height="200"
          ></v-color-picker>

          <!-- <v-switch
            v-model="instanceSyncContact"
            :label="$t('channels.syncContact')"
          ></v-switch>
          <v-row align="center" class="px-4">
            <v-checkbox v-model="instanceSettingSync" :label="$t('channels.backgroundMessage')"></v-checkbox>
            <v-btn icon plain small class="ml-1" @click="showInfoSettingSync = !showInfoSettingSync">
              <v-icon>{{ icons.mdiInformation }}</v-icon>
            </v-btn>
          </v-row>
          <span v-if="showInfoSettingSync">{{ $t('channels.infoBackgroundMessage') }}</span> -->
        </v-card-text>
        <v-divider class="bottom-action" />
        <v-card-actions class="bottom-action text-end justify-end">
          <v-btn color="#ffeeee" class="mr-3" @click="dialogEditInstances = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="saveInstancesEdit">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- dialog delete instance WA -->
    <v-dialog v-model="dialogDeleteInstance" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">
            {{ $t('warning') }}
          </h3>

          <v-spacer></v-spacer>

          <v-btn color="#ffeeee" class="mr-3 hide-buton" @click="dialogDeleteInstance = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="hide-buton" @click="deleteInstances(idDeleteInstance)">
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-card-text>
          {{ $t('deleteMessage') }}
          <!-- <v-switch
            v-model="instanceSyncContact"
            :label="$t('channels.syncContact')"
          ></v-switch>
          <v-row align="center" class="px-4">
            <v-checkbox v-model="instanceSettingSync" :label="$t('channels.backgroundMessage')"></v-checkbox>
            <v-btn icon plain small class="ml-1" @click="showInfoSettingSync = !showInfoSettingSync">
              <v-icon>{{ icons.mdiInformation }}</v-icon>
            </v-btn>
          </v-row>
          <span v-if="showInfoSettingSync">{{ $t('channels.infoBackgroundMessage') }}</span> -->
        </v-card-text>
        <v-divider class="bottom-action"> </v-divider>
        <v-card-actions class="justify-end text-end bottom-action">
          <v-btn color="#ffeeee" class="mr-3" @click="dialogDeleteInstance = false">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="deleteInstances(idDeleteInstance)">
            {{ $t('ok') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="mb-1">
      <v-col cols="12" md="4">
        <!-- <ul>
          <li
            v-for="friend in friends.value"
            :key="friend.id"
          >
            <v-btn
              @click="deleteFriend(friend.id)"
            >
              <span
                class="white-text text-subtitle-1"
              >
                {{ friend.name }}, {{ friend.age }}
              </span>
            </v-btn>
          </li>
        </ul> -->
        <!-- <pre>{{ friends }}</pre> -->
        <!-- <v-btn @click="addFriend">
          add friend
        </v-btn>
        <v-text-field
          v-model="friendName"
          dense
        ></v-text-field> -->
        <!-- <v-switch
          v-model="muteNotification"
          :disabled="muteNotificationSetting === null"
          @change="changeValueMute"
        >
          <template v-slot:label>
            {{ $t('channels.muteNotification') }}
            <v-progress-circular
              v-if="switchMute"
              :indeterminate="switchMute"
              :value="0"
              size="24"
              class="ml-2"
            ></v-progress-circular>
          </template>
        </v-switch> -->
        <!-- <v-switch
          v-model="boldUnreplied"
          :disabled="boldUnrepliedSetting === null"
          @change="changeValueBold"
        >
          <template v-slot:label>
            {{ $t('channels.boldUnreplied') }}
            <v-progress-circular
              v-if="switchBold"
              :indeterminate="switchBold"
              :value="0"
              size="24"
              class="ml-2"
            ></v-progress-circular>
          </template>
        </v-switch> -->
        <!-- <v-select
          v-if="syncSetting"
          v-model="syncSetting.value"
          item-text="label"
          item-value="value"
          :items="syncSettingOptions"
          dense
          outlined
          @input="
            value => {
              updateSyncSetting(value);
            }
          "
        ></v-select> -->
        <!-- </v-btn> -->
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="12" md="auto">
        <v-tooltip v-if="!user.limited_channel" bottom>
          <template #activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" class="mr-6 text-capitalize" v-on="on" @click="openAddInstanceDialog">
              <v-icon class="me-1">
                {{ icons.mdiPlus }}
              </v-icon>
              {{ currentTab === 2 ? `${$t('add')} Group` : `${$t('add')} Account` }}
            </v-btn>
          </template>
          <span>{{ `${$t('add')} Account` }}</span>
        </v-tooltip>
        <!-- <v-tooltip
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="success"
              v-bind="attrs"
              class="mr-6"
              v-on="on"
              @click="dialogAddInstanceTokped = true"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ `${$t('add')} Tokopedia Shop` }}</span>
        </v-tooltip> -->
        <!-- <v-tooltip
          v-if="user.role === 'Admin'"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="openDialogWorkspaces"
            >
              <v-icon>
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('workspaces.editHeader') }}</span>
        </v-tooltip> -->
      </v-col>
      <!-- <v-col
        cols="12"
        md="4"
      >
        <v-select
          v-model="activeWorkspace"
          dense
          outlined
          :items="dataWorkspaces"
          item-text="workspace_name"
          label="Workspace"
          return-object
          :no-data-text="$t('noResults')"
          @change="changeWorkspace(activeWorkspace)"
        />
      </v-col> -->
    </v-row>
    <v-tabs v-model="currentTab" center-active>
      <v-tab v-for="(column, index) in columns" :key="index + 'columns'">
        {{ column }}
      </v-tab>
    </v-tabs>
    <v-data-table
      :headers="columnsInstances"
      :items="currentTab === 2 ? bcGroupList : listInstancesFilter"
      :expanded.sync="expanded"
      item-key="_id"
      :loading="loadingTable"
      loading-text="Loading... Please wait"
      :show-expand="currentTab === 0 ? true : false"
      :footer-props="{
        'items-per-page-text': $t('rowsPerPageLabel'),
      }"
      @item-expanded="checkInstanceStatus"
    >
      <!-- <template slot="no-data">
        {{ $t('noDataLabel') }}
      </template> -->
      <template #[`item.expand.instances`]="{ item }">
        <p class="p-0 m-0" v-for="el in item.expand.instances">+{{ el.phone_number }}</p>
      </template>
      <template slot="no-data">
        <!-- <v-row v-if="currentTab === 1" align="center" class="py-16 px-4 padding-hero">
          <v-col class="text-center" lg="6" md="6" sm="12" cols="12">
            <div class="text-start hero-landing">
              <h1 class="hero">
                {{ $t('channels.noDataTitle') }}
              </h1>
              <div class="hero-subtitle hide-lottie">
                <span class="text--primary">{{ $t('channels.noDataSub') }}</span>
              </div>
              <div class="justify-center try-btn mt-3">
                <v-btn elevation="2" class="button-goLogin" color="primary" @click="openDialogAddInstance">
                  <span style="color: white"> {{ $t('add') }}</span>
                  <v-icon color="white">
                    {{ icons.mdiChevronRight }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12" class="hide-lottie">
            <div class="mx-auto" style="width: 50%">
              <lottie-player
                src="https://assets9.lottiefiles.com/packages/lf20_vgyx8n4i.json"
                background="transparent"
                speed="1"
                class="mx-auto"
                loop
                autoplay
              ></lottie-player>
            </div>
          </v-col>
        </v-row> -->
        <v-row v-if="currentTab === 1" align="center" class="py-16 px-4 padding-hero">
          <v-col class="text-center" lg="6" md="6" sm="12" cols="12">
            <div class="text-start hero-landing">
              <h1 class="hero">
                {{ $t('channels.noDataTitleFacebook') }}
              </h1>
              <div class="hero-subtitle hide-lottie">
                <span class="text--primary">{{ $t('channels.noDataSubFacebook') }}</span>
              </div>
              <div class="justify-center try-btn mt-3">
                <v-btn elevation="2" class="button-goLogin" color="primary" @click="openDialogFacebook">
                  <span style="color: white"> {{ $t('add') }}</span>

                  <v-icon color="white">
                    {{ icons.mdiChevronRight }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12" class="hide-lottie">
            <div class="mx-auto" style="width: 50%">
              <svg
                height="100%"
                style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
                version="1.1"
                viewBox="0 0 512 512"
                width="100%"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:serif="http://www.serif.com/"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  d="M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-106.468,0l0,-192.915l66.6,0l12.672,-82.621l-79.272,0l0,-53.617c0,-22.603 11.073,-44.636 46.58,-44.636l36.042,0l0,-70.34c0,0 -32.71,-5.582 -63.982,-5.582c-65.288,0 -107.96,39.569 -107.96,111.204l0,62.971l-72.573,0l0,82.621l72.573,0l0,192.915l-191.104,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Z"
                  style="fill: #1777f2"
                />
              </svg>
            </div>
          </v-col>
        </v-row>
        <v-row v-if="currentTab === 4" align="center" class="py-16 px-4 padding-hero">
          <v-col class="text-center" lg="6" md="6" sm="12" cols="12">
            <div class="text-start hero-landing">
              <h1 class="hero">
                {{ $t('channels.noDataTitleInstagram') }}
              </h1>
              <div class="hero-subtitle hide-lottie">
                <span class="text--primary">{{ $t('channels.noDataSubInstagram') }}</span>
              </div>
              <div class="justify-center try-btn mt-3">
                <v-btn elevation="2" class="button-goLogin" color="primary" @click="openDialogInstagram">
                  <span style="color: white"> {{ $t('add') }}</span>

                  <v-icon color="white">
                    {{ icons.mdiChevronRight }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col lg="6" md="6" sm="12" cols="12" class="hide-lottie">
            <div class="mx-auto" style="width: 50%">
              <svg
                height="100%"
                style="fill-rule: evenodd; clip-rule: evenodd; stroke-linejoin: round; stroke-miterlimit: 2"
                version="1.1"
                viewBox="0 0 512 512"
                width="100%"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:serif="http://www.serif.com/"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <path
                  d="M449.446,0c34.525,0 62.554,28.03 62.554,62.554l0,386.892c0,34.524 -28.03,62.554 -62.554,62.554l-106.468,0l0,-192.915l66.6,0l12.672,-82.621l-79.272,0l0,-53.617c0,-22.603 11.073,-44.636 46.58,-44.636l36.042,0l0,-70.34c0,0 -32.71,-5.582 -63.982,-5.582c-65.288,0 -107.96,39.569 -107.96,111.204l0,62.971l-72.573,0l0,82.621l72.573,0l0,192.915l-191.104,0c-34.524,0 -62.554,-28.03 -62.554,-62.554l0,-386.892c0,-34.524 28.029,-62.554 62.554,-62.554l386.892,0Z"
                  style="fill: #1777f2"
                />
              </svg>
            </div>
          </v-col>
        </v-row>
        <div v-if="currentTab === 0">
          {{ $t('noDataLabel') }}
        </div>
      </template>
      <template #[`item.status`]="{ item }">
        <template>
          <v-chip v-if="item.status == 0" color="#fbb4bb">
            {{ $t('channels.notConnected') }}
          </v-chip>
          <v-chip v-if="item.status == 1" color="#2da583">
            {{ $t('channels.connected') }}
          </v-chip>
          <v-chip v-if="item.status == 2" color="#FF4C51">
            {{ $t('channels.disconnected') }}
          </v-chip>
          <v-chip v-if="item.status == 3" color="#FF4C51">
            {{ $t('channels.disconnected') }}
          </v-chip>
          <v-chip v-if="item.status == 4" color="warning">
            {{ $t('channels.preparing') }}
          </v-chip>
        </template>
      </template>
      <template #[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              :disabled="user.limited_channel"
              icon
              small
              v-bind="attrs"
              class="cursor-pointer me-2"
              v-on="on"
              @click="openDialogEditInstances(item)"
            >
              <v-icon color="primary">{{ icons.mdiPencilOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ `${$t('edit')} ${$t('channels.channel')}` }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <!-- v-if="
                (listInstancesFilter && listInstancesFilter.length > 1) ||
                  item.hasOwnProperty('clientSecret') ||
                  item.hasOwnProperty('id_page') ||
                  item.hasOwnProperty('id_instagram') ||
                  item.hasOwnProperty('username') ||
                  item.hasOwnProperty('phone_number_id') ||
                  item.hasOwnProperty('profileKey')
              " -->
            <v-btn
              :disabled="user.limited_channel"
              icon
              small
              v-bind="attrs"
              v-on="on"
              @click="openDialogDeleteInstance(item)"
            >
              <v-icon color="primary">{{ icons.mdiDeleteOutline }}</v-icon>
            </v-btn>
          </template>
          <span>{{ `${$t('delete')} ${$t('channels.channel')}` }}</span>
        </v-tooltip>
      </template>
      <template #[`item.profile_picture`]="{ item }">
        <v-avatar size="40">
          <v-img :src="item.profile_picture" :lazy-src="defaultProfPic" />
        </v-avatar>
      </template>
      <template #[`item.label`]="{ item }">
        <!-- <template v-if="overTwoMinutes(item.is_loading)">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template> -->
        <template>
          <div v-if="item.color" class="state-circle d-inline-block" :style="{ 'background-color': item.color }" />
          <span>{{
            item.label.startsWith('-') || item.label.includes('Default') || item.label.includes('62')
              ? item.label_server
              : item.label
          }}</span>
        </template>
      </template>
      <!-- <template #[`item.label_server`]="{ item }">
        <template v-if="overTwoMinutes(item.is_loading)">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </template>
        <template>
          <div v-if="item.color" class="state-circle d-inline-block" :style="{ 'background-color': item.color }" />
          <span>{{
            item.label.startsWith('-') || item.label.includes('Default') || item.label.includes('62')
              ? item.label_server
              : item.label
          }}</span>
        </template>
      </template> -->
      <template #[`item.sync_contact`]="{ item }">
        <v-switch v-model="item.sync_contact" @change="updateSyncContact"></v-switch>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="pa-4">
            <v-col cols="auto">
              <div v-if="(!qrCodeChannel || loadQr) && item.status != 1" class="text-center loading-box">
                <v-progress-circular size="128" class="mt-15" color="primary" indeterminate></v-progress-circular>
              </div>
              <div v-if="item.status != 1 && !loadQr">
                <v-img id="qr-code" :src="qrCodeChannel"> </v-img>
                <!-- <iframe
                  id="iframe-qr-code"
                  title="QR Code"
                  scrolling="no"
                  width="266"
                  height="267"
                  style="border: 0"
                  class="loading-iframe"
                  :src="getIframeSrc(item.sub_id + '-' + item.instance_id)"
                ></iframe> -->
              </div>
              <div v-if="item.status == 1">
                <img height="260" width="284" src="@/assets/images/phone_connected_md.png" />
              </div>
            </v-col>
            <v-col v-if="item.status !== 1">
              <span class="font-weight-bold">{{ $t('channels.howToUse') }}</span>
              <br />
              <span v-html="howToUseOne"></span>
              <br />
              <span v-html="howToUseTwo"></span>
              <br />
              <span v-html="howToUseThree"></span>
              <br />
              <span v-html="howToUseFour"></span>
              <br />
              <span v-html="howToUseFive"></span>
              <br />
              <span v-html="howToUseSix"></span>
            </v-col>
            <v-col v-else>
              <span class="font-weight-bold">{{ $t('channels.howToUseConnect') }}</span>
              <br /><br />
              <span v-html="howToUseOneConnect1"></span>
              <v-btn to="inbox" plain dense color="primary" small no-padding class="font-weight-bold"> Inbox </v-btn>
              <span v-html="howToUseOneConnect2"></span>
              <br /><br />
              <span v-html="howToUseTwoConnect1"></span>
              <v-btn to="broadcast-list" plain dense color="primary" small no-padding class="font-weight-bold">
                Broadcast
              </v-btn>
              <span v-html="howToUseTwoConnect2"></span>
              <br /><br />
              <span v-html="howToUseThreeConnect1"></span>
              <v-btn to="message-template" plain dense color="primary" small no-padding class="font-weight-bold">
                Message Template
              </v-btn>
              <span v-html="howToUseThreeConnect2"></span>
              <br /><br />
              <span v-html="howToUseFourConnect1"></span>
              <v-btn
                to="/operators/replies-history"
                plain
                dense
                color="primary"
                small
                no-padding
                class="font-weight-bold"
              >
                History
              </v-btn>
              <span v-html="howToUseFourConnect2"></span>
            </v-col>
          </v-row>
        </td>
      </template>
    </v-data-table>

    <!-- dialog select label -->
    <dialogAddListContact ref="dialogAddListContact"></dialogAddListContact>
    <!-- dialog confirmation -->
    <confirm ref="confirm"></confirm>
    <!-- Dialog confirmation about profile user on ayrshare -->
    <v-dialog v-if="showAlertProfileAyrshare == true" v-model="showAlertProfileAyrshare" max-width="400">
      <v-card>
        <v-alert dense text :type="typeDialog">
          {{ messageshowAlertProfileAyrshare }}
        </v-alert>
        <v-row justify="end" class="mb-2 mr-1">
          <v-btn color="green darken-1" text @click="showAlertProfileAyrshare = false"> Close </v-btn>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Dialog for add account chatwoot -->
    <v-dialog v-model="chatwootDialog" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ $t('add') }} {{ $t('account') }}</h3>
          <v-spacer></v-spacer>
          <v-btn color="#ffeeee" class="mr-3 hide-buton" @click="closeDialogChatwoot">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="hide-buton" @click="saveChatwootAdd">
            {{ $t('add') }}
          </v-btn>
        </v-card-actions>
        <v-divider />
        <v-text-field
          v-model="chatwootAccountData.account_id"
          required
          label="Account ID (Only Number)"
          :prepend-inner-icon="icons.mdiCounter"
          outlined
          hide-details
          class="m-8"
          type="number"
          placeholder="2102"
        ></v-text-field>
        <!-- <v-text-field
          v-model="chatwootAccountData.page_id"
          required
          label="Page ID"
          :prepend-inner-icon="icons.mdiCounter"
          outlined
          hide-details
          class="m-8"
          type="text"
          placeholder="IOneZvsGy4kvRLQkaYRUjsAT"
        ></v-text-field> -->
        <v-text-field
          v-model="chatwootAccountData.access_token"
          required
          label="Access Token"
          :prepend-inner-icon="icons.mdiCounter"
          outlined
          hide-details
          class="m-8"
          type="text"
          placeholder="IOneZvsGy4kvRLQkaYRUjsAT"
        ></v-text-field>

        <v-divider class="bottom-action" />
        <v-card-actions class="bottom-action text-end justify-end">
          <v-btn color="#ffeeee" class="mr-3" @click="closeDialogChatwoot">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="" @click="saveChatwootAdd">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="errorTitleValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
        Please fill out all fields and make sure you enter a valid Chatwoot Account ID & Token!
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errorTitleValidationSnackbar = false"> {{ $t('Close') }} </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="errorChatwootValidationSnackbar" color="error" :top="true" style="z-index: 5 !important">
        Invalid Chatwoot Account ID or Token
        <template #action="{ attrs }">
          <v-btn text v-bind="attrs" @click="errorTitleValidationSnackbar = false"> {{ $t('Close') }} </v-btn>
        </template>
      </v-snackbar>
    </v-dialog>

    <v-dialog v-model="isLoadingChatwoot" persistent width="320">
      <v-card color="primary">
        <v-card-text class="pt-3 white--text">
          <span>Please Wait....</span>
          <v-progress-linear indeterminate color="white"></v-progress-linear>

          <!-- <div class="d-flex align-end justify-end">
            <v-btn
              color="success"
              class="mt-4"
              small
              @click="skipLoad"
            >
              <span>Skip</span>
            </v-btn>
          </div> -->
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog broadcast Group -->
    <v-dialog v-model="bcGroupDialog" width="600px">
      <v-card>
        <v-card-actions>
          <h3 class="font-weight-bold">{{ $t('create') }} {{ $t('group') }}</h3>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-divider />
        <v-text-field
          v-model="groupBc.name"
          required
          label="Group Name"
          outlined
          hide-details
          dense
          class="m-12"
          type="text"
          placeholder="Sunda Empire"
        ></v-text-field>
        <v-text-field
          v-model="groupBc.profileName"
          required
          label="Profile Name"
          outlined
          hide-details
          dense
          class="m-12"
          type="text"
          placeholder="John Doe"
        ></v-text-field>
        <v-text-field
          v-model="photoLinks"
          required
          label="Profile Picture"
          outlined
          hide-details
          dense
          read-only
          class="m-12"
          type="text"
          placeholder="Choose File"
          @click="openFileManager()"
        ></v-text-field>

        <p
          class="d-flex justify-end m-14"
          style="cursor: pointer; color: #1f979e"
          @click="() => (dialogAddBcGroup = true)"
        >
          + Scan Whatssap
        </p>

        <v-data-table
          class="m-12"
          :headers="columnBcGroup"
          :items="instanceBcGroup"
          :loading="loadingTable"
          loading-text="Loading... Please wait"
        >
          <template #[`item.status`]="{ item }">
            <template>
              <v-chip v-if="item.status == 0" color="#fbb4bb">
                {{ $t('channels.notConnected') }}
              </v-chip>
              <v-chip v-if="item.status == 1" color="#2da583">
                {{ $t('channels.connected') }}
              </v-chip>
              <v-chip v-if="item.status == 2" color="#FF4C51">
                {{ $t('channels.disconnected') }}
              </v-chip>
              <v-chip v-if="item.status == 3" color="#FF4C51">
                {{ $t('channels.disconnected') }}
              </v-chip>
              <v-chip v-if="item.status == 4" color="warning">
                {{ $t('channels.preparing') }}
              </v-chip>
            </template>
          </template>

          <template #[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  :disabled="user.limited_channel"
                  icon
                  small
                  v-bind="attrs"
                  v-on="on"
                  @click="openDialogDeleteInstance(item)"
                >
                  <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                </v-btn>
              </template>
              <span>{{ `${$t('delete')} ${$t('channels.channel')}` }}</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <v-card-actions class="d-flex justify-end mt-10">
          <v-btn color="#ffeeee" class="mr-3 hide-buton" @click="() => (bcGroupDialog = false)">
            <span class="error--text">{{ $t('cancel') }}</span>
          </v-btn>
          <v-btn color="success" class="hide-buton" @click="saveBcGroup">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog scan wa bc group -->
    <v-dialog v-model="dialogAddBcGroup" width="600px">
      <v-card>
        <v-row class="pa-4">
          <v-col cols="auto">
            <!-- {{ qrCode }} -->
            <div v-if="(!qrCode || loadQr) && createLocalInstance.status !== 1" class="text-center loading-box">
              <v-progress-circular size="128" class="mt-15" color="primary" indeterminate></v-progress-circular>
            </div>
            <div v-if="!loadQr && createLocalInstance.status !== 1">
              <v-img id="qr-code" :src="qrCode"> </v-img>
              <!-- <iframe
                id="iframe-qr-code"
                scrolling="no"
                class="loading-iframe"
                title="QR Code"
                width="266"
                height="267"
                style="border: 0"
                :src="getIframeSrc(createLocalInstance.sub_id + '-' + createLocalInstance.instance_id)"
              ></iframe> -->
            </div>
            <div
              v-if="
                !createLocalInstance.is_adding && (createLocalInstance.status == 1 || createLocalInstance.status == 4)
              "
            >
              <img height="260" width="284" src="@/assets/images/phone_connected_md.png" />
            </div>
          </v-col>
          <v-col>
            <span class="font-weight-bold">{{ $t('channels.howToUse') }}</span>
            <br />
            <span v-html="howToUseOne"></span>
            <br />
            <span v-html="howToUseTwo"></span>
            <br />
            <span v-html="howToUseThree"></span>
            <br />
            <span v-html="howToUseFour"></span>
            <br />
            <span v-html="howToUseFive"></span>
            <br />
            <span v-html="howToUseSix"></span>

            <div class="text-center mt-16">
              <v-chip v-if="createLocalInstance.status == 0" color="#fbb4bb">
                {{ $t('channels.notConnected') }}
              </v-chip>
              <v-chip v-if="createLocalInstance.status == 1" color="#2da583">
                {{ $t('channels.connected') }}
              </v-chip>
              <v-chip v-if="createLocalInstance.status == 2" color="#FF4C51">
                {{ $t('channels.disconnected') }}
              </v-chip>
              <v-chip v-if="createLocalInstance.status == 3" color="#FF4C51">
                {{ $t('channels.disconnected') }}
              </v-chip>
              <v-chip v-if="createLocalInstance.status == 4" color="warning">
                {{ $t('channels.preparing') }}
              </v-chip>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
/* eslint-disable */

// import built in
import { default as clientPocketBase } from '@/helpers/pocketbase'
import { mdiDeleteOutline, mdiInformation, mdiLanDisconnect, mdiPencilOutline, mdiPlus } from '@mdi/js'
import PouchDB from 'pouchdb-browser'
import channelMixin from '../mixins/channelMixin'
import firestoreDbMixin from '../mixins/firestoreDbMixin'
import pouchDbMixin from '../mixins/pouchDbMixin'
// import custom
import axios from 'axios'
import { db } from '../helpers/db.js'
// import { liveQuery } from 'dexie'
// import { useObservable } from '@vueuse/rxjs'
import { fileManagerModal } from 'mini-file-manager'
import confirm from './component/Confirm.vue'
import dialogAddListContact from './component/DialogAddListContact.vue'
import fileManagerOptions from './MyAssets/options/optionsFileManager.js'
const API_KEY = process.env.VUE_APP_AYRSHARE_KEY
const fetch = require('node-fetch')

export default {
  name: 'Channels',
  mixins: [pouchDbMixin, channelMixin, firestoreDbMixin],
  components: {
    dialogAddListContact,
    confirm,
  },
  data() {
    return {
      // friends: useObservable(liveQuery(() => db.friends.toArray())),
      groupBc: {
        profilePicture: '',
        name: '',
        profileName: '',
      },
      isLoadingChatwoot: false,
      status: '',
      bcGroupDialog: false,
      qrCode: '',
      qrCodeChannel: '',
      loadQr: false,
      intervalId: null,
      friendName: '',
      friendAge: 21,
      instances: {
        value: [],
      },
      getIframeSrcCount: 0,
      currentTab: 0,
      currentTab2: 0,
      messenger: 'Messenger',
      // columns: ['WhatsApp', 'Tokopedia', 'Facebook', 'Lazada', 'Twitter', 'Instagram'],
      columns: ['WhatsApp', 'Social Media'],
      //  columns: ['WhatsApp'],
      test: null,
      // template
      icons: {
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiPlus,
        mdiInformation,
        mdiLanDisconnect,
      },
      // pouchdb
      dbSettings: null,
      dbRemoteSettings: null,
      replicationSettings: null,
      // workspaces
      activeWorkspace: null,
      dialogEditWorkspaces: false,
      workspaceName: '',
      // instances
      dialogEditInstances: false,
      dialogDeleteInstance: false,
      dialogAddInstance: false,
      idDeleteInstance: null,
      isNewModeInstances: false,
      instanceName: '',
      instanceColor: '',
      instanceSyncContact: true,
      instanceSettingSync: false,
      selectedInstance: null,
      expanded: [],
      openInstance: null,
      loading: false,
      // instances: null,
      createLocalInstance: {
        is_adding: false,
      },
      // sync setting
      syncSetting: null,
      showInfoSettingSync: false,

      //switch setting
      muteNotificationSetting: null,
      boldUnrepliedSetting: null,
      muteNotification: false,
      boldUnreplied: false,
      switchMute: false,
      switchBold: false,

      dialogAlert: false,
      alertText: '',

      dbSettingsWatcher: null,
      loadingTable: false,

      //! tokopedia
      dialogAddInstanceTokped: false,
      dialogEditInstanceTokped: false,
      tokopediaEditPayload: {
        clientSecret: '',
        clientId: '',
        fs_id: '',
        shop_id: '',
        color: '',
        label: '',
      },
      tokopediaAddPayload: {
        clientSecret: '',
        clientId: '',
        fs_id: '',
        shop_id: '',
      },
      Page: [],
      waCloudPayload: {
        businessName: '',
        token: '',
        businessId: '',
        phone_number: '',
        phone_number_id: '',
      },
      allPageData: [],
      activatorButtonLinkToSosmed: false,
      urlSocialNetwork: '',
      tokenAyrshare: '',
      activeUserAyrshare: false,
      lengthProfileAyrshare: 0,
      showAlertProfileAyrshare: false,
      messageshowAlertProfileAyrshare: '',
      typeDialog: '',
      chatwootDialog: false,
      chatwootAccountData: {
        account_id: '',
        access_token: '',
        sub_id: '',
      },
      errorTitleValidationSnackbar: false,
      errorChatwootValidationSnackbar: false,
      columnBcGroup: [
        { text: 'Name', value: 'label' },
        { text: 'Phone Number', value: 'phone_number', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: this.$t('channels.actions'), value: 'actions', sortable: false },
      ],
      dialogAddBcGroup: false,
      instanceBcGroup: [],
      photoLinks: '',
      bcGroupList: [],
      joinedError: '',
      errorNewChatEmpty: false,
    }
  },
  async mounted() {
    ////console.log(this.user,"00aisoajsiohai bsahjklvdghksvwcjklbswjkcdnhilwjvd")
    // await clientPocketBase.admins.authViaEmail(process.env.VUE_APP_POCKETBASE_USER, process.env.VUE_APP_POCKETBASE_PASS)
    // const test = await clientPocketBase.collection().getFullList('rooms', 1000)
    // //console.log(test, clientPocketBase.authStore, '<<< instances')

    clientPocketBase.collection('crm_instances').subscribe('*', e => this.updateInstance(e))
    clientPocketBase.collection('crm_contact_group').subscribe('*', e => this.fetcAllBcGroup())

    // const REALTIME_URL = 'wss://realtime.superwa.io/socket'
    // const client = new RealtimeClient(REALTIME_URL)
    // client.connect()
    // client.onOpen(() => console.log('Socket opened.'))
    // client.onClose(() => console.log('Socket closed.'))
    // client.onError(e => console.log('Socket error', e))

    // // const databaseChanges = client.channel(`realtime:${this.user.sub_id}:settings`)
    // // databaseChanges.on('UPDATE', e => this.updateSetting(e))
    // // databaseChanges.subscribe()

    // const twitterDb = client.channel(`realtime:public:twitter_subscriptions`)
    // twitterDb.on('INSERT', e => this.addTwitterInstance(e))
    // twitterDb.subscribe()
    // //console.log('the user :', this.user)

    // const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
    //   sub_id: this.user.sub_id,
    //   token: this.user.token,
    //   key: 'instances',
    // })

    const listInstances = await clientPocketBase.collection('crm_instances').getList(1, 50, {
      filter: `sub_id = '${this.user.sub_id}'`,
      expand: 'instance_label_name',
      $autoCancel: false,
    })

    // const listInstances = await clientPocketBase.collection().getFullList(20)
    //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1463 ~ mounted ~ listInstances', listInstances)

    if (listInstances?.items?.length > 0) {
      // //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1465 ~ mounted ~ listInstances', listInstances)
      const mappedInstances = listInstances.items.map(instance => {
        instance.is_loading = false

        return instance
      })

      mappedInstances.forEach(inst => {
        // if (ele.edited_name) {
        //   ele.label = ele.edited_name
        //   ele.label_server = ele.edited_name
        // }
        if (Object.keys(inst.expand).length) {
          const editedName = inst.expand.instance_label_name.edited_name
          if (editedName) {
            inst.label = editedName
            inst.label_server = editedName
          }
        }
      })

      this.instances.value = mappedInstances.filter(instance => {
        if (this.user.limited_channel) {
          return (
            this.user.enabled_channel &&
            this.user.enabled_channel.find(channel => channel === instance._id || channel === instance.phone_number)
          )
        }
        return true
      })

      this.$store.commit('auth/setListInstance', this.instances)
    }

    // try {
    //   const rooms = (
    //     await clientPocketBase.collection().getFullList(
    //       'contact',
    //       400,
    //       // {
    //       //   filter: `instance = '${this.$Md5Hash(instanceId)}'`,
    //       // }
    //     )
    //   ).map(room => room.id)
    //   //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1488 ~ mounted ~ rooms', rooms)
    //   if (rooms.length) {
    //     for (let i = 0; i < rooms.length; i++) {
    //       const room = rooms[i]
    //       try {
    //         await clientPocketBase.collection().delete('contact', room)
    //       } catch (err) {}
    //     }
    //   }
    // } catch (err) {
    //   //console.log('🚀 ~ file: ChannelsMD.vue ~ line 2947 ~ deleteInstances ~ err', err)
    // }

    // try {
    //   const messages = (
    //     await clientPocketBase.collection().getFullList('messages', 400, {
    //       filter: `original_message ~ 'Nick'`,
    //     })
    //   ).map(message => message.id)
    //   //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1511 ~ mounted ~ messages', messages)
    //   if (messages.length) {
    //     for (let i = 0; i < messages.length; i++) {
    //       const message = messages[i]

    //       try {
    //         await clientPocketBase.collection().delete('messages', message)
    //       } catch (err) {}
    //     }
    //   }
    // } catch (err) {
    //   //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1520 ~ mounted ~ err', err)
    // }

    // if (instancePg.status) {
    //   this.instances = instancePg.data
    //   // //console.log(this.listInstances, this.instances, `INI INSTANCE`)
    // }
    // Listen to events on the entire database.
    // window.addEventListener('message', this.receiveMessage, false)

    // await this.fdbGetInstances(this.user.sub_id)

    // this.instances = this.listInstances
    this.loading = true
    // await this.checkInstances()
    this.loading = false
    this.loadingTable = false
    // if (this.allSettings.length === 0) {
    //   this.loadingTable = true
    // } else {
    //   await this.getSettings()
    // }
    // this.loadingTable = true
    // this.switchMute = true
    // this.switchBold = true

    // await this.$store.dispatch('auth/getChannelAccess', {
    //   sub_id: this.user.sub_id,
    //   email: this.user.email,
    //   token: this.user.token,
    // })
    // await this.connectSettings()

    if (this.instances) {
      if (this.instances.value) {
        this.instances.value.forEach(async instance => {
          if (instance.status != 1) {
            await this.expandInstances(instance)
          }
        })
      }
      // else {
      //   const items = await this.mixinLoadSettingsRemote()
      //   const instancesSetting = items.find(x => x.key === 'instances')
      //   this.$store.commit('auth/setListInstance', instancesSetting)
      //   instancesSetting.value.forEach(instance => {
      //     if (instance.status == 0) {
      //       this.expandInstances(instance)
      //     }
      //   })
      //   // this.instances = this.listInstances
      // }
    }

    // await this.$store.dispatch('auth/fetchUserWorkspace', {
    //   email: this.user.email,
    // })
    // await this.$store.dispatch('auth/fetchListUser', {
    //   sub_id: this.user.sub_id,
    // })

    this.dataWorkspaces.forEach(el => {
      if (el.sub_id === this.user.sub_id) {
        this.activeWorkspace = el
      }
    })
    //console.log(this.listInstancesFilter, '<<< INI CHANNEL')

    this.loadingTable = false
    this.switchMute = false
    this.switchBold = false
    // this.$eventBus.$on(process.env.VUE_APP_EVENT_UPDATE_SETTINGS, payload => {
    //   if (payload.doc._id === 'instances' && this.instances) {
    //     if (this.instances.value.length === payload.doc.value.length) {
    //       for (var instance of this.instances.value) {
    //         const obj = payload.doc.value.find(x => x.instance_id === instance._id)
    //         if (obj) {
    //           if (instance.label === '-') {
    //             instance.label = obj.label_server
    //           }
    //           instance.is_loading = obj.is_loading
    //         }
    //       }
    //       this.$store.commit('auth/setListInstance', this.instances)
    //     }
    //   }
    //   // this.$store.dispatch('global/setSettingsById', payload)
    // })
    // await this.loadFacebookSDK(document, 'script', 'facebook-jssdk')
    // await this.initFacebook()
    // await this.checkAccessTokenFacebook()

    // check is the workspace has profile on ayrshare
    // const registeredProfile = await this.$store.dispatch('global/fetchProfileAyrshareBysubid', {
    //   token: this.user.token,
    //   sub_id: this.user.sub_id,
    // })
    // //console.log(API_KEY, 'INI API KEY')
    // // check how many registered profile on ayrshare
    // await fetch('https://app.ayrshare.com/api/profiles', {
    //   method: 'GET',
    //   headers: {
    //     Authorization: `Bearer ${API_KEY}`,
    //   },
    // })
    //   .then(res => res.json())
    //   .then(users => {
    //     this.lengthProfileAyrshare = users.profiles.lengthProfileAyrshare
    //     users.profiles.forEach(user => {
    //       if (user.refId == registeredProfile?.data[0]?.refId) {
    //         this.activeUserAyrshare = registeredProfile.data[0]
    //         if (user?.activeSocialAccounts !== registeredProfile?.data[0]?.activeSocialAccounts) {
    //           this.activeUserAyrshare.activeSocialAccounts = user.activeSocialAccounts
    //           //console.log(this.activeUserAyrshare, 'this.activeUserAyrshare')
    //           const formattedUser = {
    //             profileKey: registeredProfile.data[0].profileKey,
    //             activeSocialAccounts: user.activeSocialAccounts,
    //             refId: user.refId,
    //             title: user.title,
    //           }
    //           this.updateInstanceAyrshare(formattedUser)
    //         }
    //       }
    //     })
    //   })
    //   .catch(console.error)

    // if (this.activeUserAyrshare.length === 0) {
    //   await this.$store.dispatch('global/deleteProfileOnAyrshare', {
    //     profileKey: this.activeUserAyrshare.profileKey,
    //   })
    // }
    this.fetchBcgroupInstance()
    this.fetcAllBcGroup()
  },
  beforeDestroy() {
    this.$eventBus.$off(process.env.VUE_APP_EVENT_UPDATE_SETTINGS)
    if (this.dbSettingsWatcher !== null) this.dbSettingsWatcher.cancel()
    if (this.intervalId) clearInterval(this.intervalId)
    clientPocketBase.realtime.unsubscribe('instances')
    window.removeEventListener('message', this.receiveMessage, false)
  },
  methods: {
    async fetcAllBcGroup() {
      const bcGroup = await clientPocketBase.collection('crm_contact_group').getList(1, 50, {
        expand: 'instances',
        $autoCancel: false,
      })

      this.bcGroupList = bcGroup.items
    },
    closeErrorNewChatSnackbar() {
      this.errorNewChatEmpty = false
      this.joinedError = ''
    },
    async saveBcGroup() {
      try {
        await clientPocketBase.collection('crm_contact_group').create({
          group_name: this.groupBc.name,
          profile_name: this.groupBc.profileName,
          profile_picture: this.photoLinks,
          instances: this.instanceBcGroup.map(el => el.id),
          sub_id: this.user.sub_id,
        })
        for (let i = 0; i < this.instanceBcGroup.length; i++) {
          const instance = this.instanceBcGroup[i]
          const subidInstance = `${this.user.sub_id}-${instance._id}`
          const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/changeProfile`
          const body = {
            subidInstance,
            profile_name: this.groupBc.profileName,
            profile_picture: this.photoLinks,
            phone_number: instance.phone_number,
          }
          this.$axios
            .post(endpoint, body)
            .then(response => {
              // db.showedRooms
              //   .update(room.id, { unread_count: 0, unreadCount: 0 })
              //   .then(res => {})
              //   .catch(err => {})
              // //console.log(body)
            })
            .catch(error => {
              console.log(error)
            })
        }
        this.instanceBcGroup = []
        this.bcGroupDialog = false
      } catch (error) {
        console.log(error)
      }
    },
    openFileManager() {
      fileManagerModal(fileManagerOptions(this.user.sub_id), this.saveFile)
    },
    async saveFile(data) {
      //console.log('sayang', data);
      const images = Promise.all(
        data.map(async el => {
          const res = 'https://fm.prod.marketa.id/uploads/' + el.uploadRelativePath
          this.photoLinks = res
        }),
      )
    },
    async fetchBcgroupInstance() {
      try {
        let foundInstances = await clientPocketBase.collection('crm_instances').getFullList(200, {
          filter: `sub_id = '${this.user.sub_id}' && broadcast_group = true`,
          $autoCancel: false,
        })

        try {
          let foundBcGroup = await clientPocketBase.collection('crm_contact_group').getFullList(200, {
            $autoCancel: false,
          })

          if (foundBcGroup.length > 0) {
            for (let i = 0; i < foundInstances.length; i++) {
              const instance = foundInstances[i]

              for (let j = 0; j < foundBcGroup.length; j++) {
                const bcGroup = foundBcGroup[j]

                const includeFlag = bcGroup.instances.includes(instance.id)

                if (!includeFlag) {
                  this.instanceBcGroup.push(instance)
                }
              }
            }
          } else {
            this.instanceBcGroup = foundInstances
          }
        } catch (error) {
          console.log(error)
        }
      } catch (error) {
        console.log(error)
      }
    },
    async updateInstance({ action, record }) {
      //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1612 ~ updateInstance ~ action, record ', action, record)
      if (action === 'update') {
        //console.log('viraa 1612 masuk')
        const indexInstance = this.instances.value.findIndex(instance => instance._id === record._id)
        let labelName
        if (record.instance_label_name) {
          try {
            labelName = await clientPocketBase
              .collection('crm_instance_label_names')
              .getOne(`${record.instance_label_name}`, { $autoCancel: false })
          } catch (error) {
            console.log('🚀 ~ file: ChannelsMD.vue:1705 ~ updateInstance ~ error):', error)
          }
        }
        if (indexInstance !== -1) {
          this.instances.value[indexInstance] = record
          // this.instances.value[indexInstance].status = record.status
          // this.instances.value[indexInstance].color = record.color
          // this.instances.value[indexInstance].label = record.label
          // this.instances.value[indexInstance].phone_number = record.phone_number
          // this.instances.value[indexInstance].label_server = labelName.edited_name || record.label_server
          // this.instances.value[indexInstance].inbox_id = record.inbox_id
          //console.log(
          //   '🚀 ~ file: ChannelsMD.vue ~ line 1622 ~ updateInstance ~ this.instances.value[indexInstance]',
          //   indexInstance,
          //   this.instances.value[indexInstance],
          // )
          this.$store.commit('auth/setListInstance', this.instances)
        }
      } else if (action === 'delete') {
        const indexInstance = this.instances.value.findIndex(instance => instance._id === record._id)
        if (indexInstance !== -1) {
          this.instances.value = this.instances.value.filter(instance => instance._id !== record._id)
          this.$store.commit('auth/setListInstance', this.instances)
        }
      } else if (action === 'create') {
        const indexInstance = this.instances.value.findIndex(instance => instance._id === record._id)
        let labelName
        if (record.instance_label_name) {
          try {
            labelName = await clientPocketBase
              .collection('crm_instance_label_names')
              .getOne(`${record.instance_label_name}`)
          } catch (error) {
            console.log('🚀 ~ file: ChannelsMD.vue:1705 ~ updateInstance ~ error):', error)
          }
        }
        if (indexInstance === -1) {
          this.instances.value.push({
            status: record.status,
            color: record.color,
            label: labelName?.edited_name || record.label,
            inbox_id: record.inbox_id,
          })

          // if (this.currentTab2 === 2) {
          //   await clientPocketBase.collection('crm_instances').update(record.id, {
          //     broadcast_group: true,
          //   })
          // }
          this.$store.commit('auth/setListInstance', this.instances)
        }
      }
    },
    checkInstanceStatus({ item, value }) {
      if (value) {
        this.startCheckStatus(item.sub_id, item.instance_id)
      } else {
        if (this.intervalId) clearInterval(this.intervalId)
        this.qrCodeChannel = ''
      }
      //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1574 ~ item', item, value)
    },
    startCheckStatus(subId, instanceId, onAddInstance = false) {
      const parent = this
      console.log('woya<')

      this.intervalId = window.setInterval(async () => {
        //console.log('🚀 ~ file: ChannelsMD.vue ~ line 2828 ~ this.intervalId=window.setInterval ~ true', true)
        let needQr = false
        try {
          const { data: authStatus } = await parent.$axiosLimit.get(
            `${process.env.VUE_APP_WA_SERVICES_URL}sessions/status/${subId}-${instanceId}`,
          )
          console.log(authStatus, 'halo')
          //console.log(
          //   '🚀 ~ file: ChannelsMD.vue ~ line 2831 ~ this.intervalId=window.setInterval ~ authStatus',
          //   authStatus,
          // )
          if (authStatus.data.status === 'authenticated') {
            if (parent.intervalId) clearInterval(parent.intervalId)

            if (!onAddInstance) {
              const indexInstance = parent.instances.value.findIndex(instance => instance._id === instanceId)
              if (indexInstance > -1) {
                parent.instances.value[indexInstance].status = 1
                this.$store.commit('global/setCurrentScanned', parent.instances.value[indexInstance])
              }
              this.isNewModeinstances = false
            } else {
              parent.createLocalInstance.status = 1
              this.$store.commit('global/setCurrentScanned', parent.createLocalInstance)
              this.isNewModeinstances = true
            }
            await parent.saveInstances()

            if (onAddInstance) parent.qrCode = ''
            else parent.qrCodeChannel = ''
          } else if (authStatus.data.status === 'connected') {
            // //console.log(
            //   '🚀 ~ file: ChannelsMD.vue ~ line 1616 ~ this.intervalId=window.setInterval ~ !this.qrCodeChannel',
            //   parent.qrCodeChannel,
            // )
            if (!parent.qrCodeChannel && !onAddInstance) {
              await parent.$axiosLimit.delete(
                `${process.env.VUE_APP_WA_SERVICES_URL}sessions/delete/${subId}-${instanceId}`,
              )
              needQr = true
            }
            if (!parent.qrCode && onAddInstance) {
              await parent.$axiosLimit.delete(
                `${process.env.VUE_APP_WA_SERVICES_URL}sessions/delete/${subId}-${instanceId}`,
              )
              needQr = true
            }
          } else if (!authStatus.status && authStatus.message === 'number already exists') {
            // this.dialogAddInstance = false
            this.errorNewChatEmpty = true
            this.joinedError = `Channel already exists!`
          }
        } catch (err) {
          //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1593 ~ this.intervalId=window.setInterval ~ err', err)
          if (err?.response?.data?.message === 'Session not found.') {
            needQr = true
          }
        }

        if (needQr) {
          parent.loadQr = true

          const { data: qrCode } = await parent.$axiosLimit.post(`${process.env.VUE_APP_WA_SERVICES_URL}sessions/add`, {
            id: `${subId}-${instanceId}`,
            broadcast_group: this.currentTab2 === 2 ? true : false,
            isLegacy: false,
          })

          if (onAddInstance) parent.qrCode = qrCode?.data?.qr
          else parent.qrCodeChannel = qrCode?.data?.qr
          parent.loadQr = false
        }
      }, 3000)
    },
    async initWa(subId, instanceId, onAddInstance = false) {
      let needQr = false
      try {
        await this.$axiosLimit.get(`${process.env.VUE_APP_WA_SERVICES_URL}sessions/status/${subId}-${instanceId}`)
      } catch (err) {
        if (err?.response?.data?.message === 'Session not found.') {
          needQr = true
        }
      }

      if (needQr) {
        this.loadQr = true

        const { data: qrCode } = await this.$axiosLimit.post(`${process.env.VUE_APP_WA_SERVICES_URL}sessions/add`, {
          id: `${subId}-${instanceId}`,
          broadcast_group: this.currentTab2 === 2 ? true : false,
          isLegacy: false,
        })
        if (onAddInstance) this.qrCode = qrCode?.data?.qr
        else this.qrCodeChannel = qrCode?.data?.qr
        this.loadQr = false
      }
    },
    async deleteFriend(id) {
      const res = await db.friends.delete(id)
      //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1442 ~ deleteFriend ~ res', res)
    },
    async addFriend() {
      try {
        // Add the new friend!
        const id = await db.friends.add({
          name: this.friendName,
          age: this.friendAge,
        })

        this.status = `Friend ${this.friendName}
          successfully added. Got id ${id}`

        // Reset form:
        this.friendName = ''
        this.friendAge = defaultAge
      } catch (error) {
        this.status = `Failed to add
          ${friendName}: ${error}`
      }
    },
    async saveWaCloud() {
      //console.log(this.waCloudPayload)
      const url = `http://localhost:80/superwa/couchdb/wa-cloud/subscription/${this.user.sub_id}`
      const email = this.user.email
      const payload = {
        name: this.waCloudPayload.businessName,
        token: this.waCloudPayload.token,
        business_id: this.waCloudPayload.businessId,
        phone_number: this.waCloudPayload.phone_number,
        phone_number_id: this.waCloudPayload.phone_number_id,
        email,
      }
      const res = await this.$axios.post(url, payload)
      if (res.status) {
        this.dialogAddInstance = false
        this.currentTab = 3
      }
    },
    async updateSetting(data) {
      if (data && data.record && data.record.key == 'instances') {
        if (data.record.value) {
          data.record.value = JSON.parse(data.record.value)
        }
        //console.log(data.record, 'channelsmd2')
        // this.instances = data.record
        await this.checkInstances
      }
    },
    // async findListPage(userId) {

    // },

    async handleTwitterLogin() {
      // //console.log('user email :', this.user.email)
      const baseUrl = process.env.VUE_APP_DB_API_URL
      // const baseUrl = 'https://d9fc-118-136-83-194.ngrok.io'
      const loginUrl = `${baseUrl}superwa/couchdb/twitter/get-token?sub_id=${this.user.sub_id}`
      const popup = 'top=100,width=600,height=600,left=320'
      const openedWindow = window.open(loginUrl, '_blank', popup)
    },
    async addTwitterInstance(data) {
      if (this.user.sub_id === data.record.sub_id) {
        // might need revision in case index 0 of the array of instances is not always the instance of whatsapp causes a problem
        let color = Math.floor(Math.random() * 16777215).toString(16)

        while (color.length < 6) {
          color = Math.floor(Math.random() * 16777215).toString(16)
        }
        const instance_id = this.$nanoid().toLowerCase()
        const payload = {
          _id: instance_id,
          instance_id,
          sub_id: this.user.sub_id,
          label: data.record.username,
          color: '#' + color,
          sync_contact: true,
          setting_sync: false,
          phone_number: data.record.twitter_id,
          status: 1,
          is_loading: 0,
          label_server: data.record.username,
          access_token: data.record.access_token,
          username: '@' + data.record.username,
        }
        this.instances.value.push(payload)
        this.$store.commit('auth/setListInstance', this.instances)
        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'instances',
          value: this.instances.value,
        })
        this.dialogAddInstance = false
        this.currentTab = 2
      }
    },
    // Lazada Processes Start
    async connectLazadaAccount() {
      //console.log(this.$router.params, 'Params dari hasil redirect')

      // temporary credential
      const { VUE_APP_LAZADA_APP_KEY, VUE_APP_LAZADA_REDIRECT_URL } = process.env
      const client_id = VUE_APP_LAZADA_APP_KEY
      const redirect_uri = VUE_APP_LAZADA_REDIRECT_URL
      //console.log('Temporary app key :', client_id)
      //console.log('Temporary app redirect URL :', redirect_uri)
      const query = {
        response_type: 'code',
        force_auth: true,
        redirect_uri,
        client_id,
      }
      // auth link
      // https://auth.lazada.com/oauth/authorize?response_type=code&force_auth=true&redirect_uri=https://be-tokopedia.deploy.cbs.co.id/superwa/couchdb/lazada/get-seller&client_id=108130
      // //console.log(this.$router.currentRoute)
      // const slug = `https://auth.lazada.com/oauth/authorize?response_type=${query.response_type}&force_auth=${
      //   query.response_type
      // }&redirect_uri=${`http://localhost:8080/channels`}&client_id=${query.client_id}`
      const slug = `https://auth.lazada.com/oauth/authorize?response_type=${query.response_type}&force_auth=${query.response_type}&redirect_uri=${query.redirect_uri}&client_id=${query.client_id}`
      window.open(slug, '_blank')
    },
    // Lazada Processes End

    async logOutWithFacebook() {
      const instancesValue = this.instances.value

      let updateInstanceFacebook = instancesValue.filter((item, index) => {
        return item.hasOwnProperty('page_name')
      })

      if (updateInstanceFacebook.length !== 0) {
        for (let i = 0; i < updateInstanceFacebook.length; i++) {
          updateInstanceFacebook[i].status = 0
        }

        this.$store.commit('auth/setListInstance', this.instances)
        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'instances',
          value: this.instances.value,
        })
      }

      await window.FB.logout(async function (response) {
        if (response) {
          alert('You are logged out &amp; cookie set!')
        } else {
          alert('User cancelled login or did not fully authorize.')
        }
      })
      return false
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.VUE_APP_FB_APP_ID, //You will need to change this
          autoLogAppEvents: true,
          xfbml: true,
          cookie: true, // This is important, it's not enabled by default
          version: 'v13.0',
        })
      }
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) {
        return
      }
      js = d.createElement(s)
      js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    },
    async logInWithFacebook() {
      const self = this
      // let isConnect = false
      // await window.FB.getLoginStatus(response => {
      //   if (response.status === 'connected') {
      //     isConnect = true
      //   }
      // })

      // if (!isConnect) {
      await window.FB.login(
        response => {
          if (response.authResponse) {
            window.FB.api(`/${response.authResponse.userID}/accounts`, async res => {
              const { data } = res
              // self.allPageData = data
              data.forEach(async (page, index) => {
                self.addFacebookInstance(
                  {
                    access_token: response.authResponse.accessToken,
                    userID: response.authResponse.userID,
                    pages_access_token: page.access_token,
                    pageID: page.id,
                    pageName: page.name,
                  },
                  page,
                )
              })
            })
          } else {
            //console.log('Error saat login')
          }
        },
        {
          return_scopes: true,
          scope:
            'public_profile,pages_show_list,email,pages_read_engagement,pages_manage_metadata,pages_messaging,instagram_basic,instagram_manage_messages,instagram_manage_comments,instagram_content_publish,pages_read_engagement,pages_read_user_content,user_photos',
        },
      )
      // } else {
      //   //console.log('ERROR ON LOGIN')
      // }

      return false
    },
    async addFacebookInstance(data) {
      const self = this
      let color = Math.floor(Math.random() * 16777215).toString(16)
      while (color.length < 6) {
        color = Math.floor(Math.random() * 16777215).toString(16)
      }

      var findIndex = this.instances.value.findIndex(function (item) {
        return item.phone_number == data.pageID
      })

      let instance_id

      if (findIndex === -1) {
        instance_id = this.$nanoid().toLowerCase()
      } else {
        instance_id = this.instances.value[findIndex]._id
        this.instances.value.splice(findIndex, 1)
      }

      let instancePayload = {
        _id: instance_id,
        instance_id,
        sub_id: this.user.sub_id,
        label: data.pageName,
        color: '#' + color,
        sync_contact: false,
        setting_sync: false,
        phone_number: data.pageID,
        status: 1,
        is_loading: 0,
        label_server: data.pageName,
        page_name: data.pageName,
        id_page: data.pageID,
        id_facebook: data.userID,
        pagesAccessToken: data.pages_access_token,
        access_token: data.access_token,
      }

      const webhookPayload = {
        subId: this.user.sub_id,
        instanceId: instance_id,
        page: {
          id: data.pageID,
          accessToken: data.pages_access_token,
          name: data.pageName,
        },
      }
      const response = await fetch(
        `${process.env.VUE_APP_FB_SERVICES_URL}webhook/register?userAccessToken=${data.access_token}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(webhookPayload),
        },
      )

      const responseData = await response.json()
      //console.log(responseData, '<<< RESPONSE FACEBOOK')
      if (responseData.longAccessToken) {
        instancePayload.access_token = responseData.longAccessToken
        instancePayload.instance_id = responseData.subs[0].instanceId
        instancePayload._id = responseData.subs[0].instanceId
        // instancePayload.instagram_business_account = responseData.instagramId

        const responseInstagram = await fetch(
          `https://graph.facebook.com/v14.0/${instancePayload.id_page}?fields=instagram_business_account&access_token=${responseData.longAccessToken.access_token}`,
        )

        const dataInstagram = await responseInstagram.json()

        // //console.log(dataInstagram, 'INI INSTAGRAM RESPONSE')

        if (dataInstagram.instagram_business_account) {
          instancePayload.instagram_business_account = dataInstagram.instagram_business_account
        }
        self.instances.value.push(instancePayload)
        self.$store.commit('auth/setListInstance', self.instances)

        self.$store.dispatch('global/updateSettingsByKey', {
          sub_id: self.user.sub_id,
          token: self.user.token,
          key: 'instances',
          value: self.instances.value,
        })
      }

      self.dialogAddInstance = false
      self.currentTab = 5
    },
    async checkAccessTokenFacebook() {
      const instancesValue = this.instances.value

      let updateInstanceFacebook = instancesValue.filter((item, index) => {
        return item.hasOwnProperty('page_name')
      })

      //console.log(updateInstanceFacebook, 'CHECK FB IS LOGGED IN')

      if (updateInstanceFacebook.length !== 0) {
        for (let i = 0; i < updateInstanceFacebook.length; i++) {
          if (updateInstanceFacebook[i].access_token.expires_in > 0) {
            continue
          } else {
            updateInstanceFacebook[i].status = 0
          }
        }
        this.$store.commit('auth/setListInstance', this.instances)
        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'instances',
          value: this.instances.value,
        })
      }
    },
    async filterFacebookInstances() {
      if (this.instances) {
        const facebookInstances = this.instances.value.filter(function (item) {
          if (item.id_page) {
            return true
          }
        })
        return facebookInstances
      }
      return []
    },
    async openDialogFacebook() {
      //console.log('masuk sini woy')
      this.chatwootDialog = true
      // this.dialogAddInstance = true
      // this.currentTab2 = 1
      // await this.createInstance()
    },
    async closeDialogChatwoot() {
      this.chatwootDialog = false
    },
    async saveChatwootAdd() {
      try {
        if (this.chatwootAccountData.account_id === '' || this.chatwootAccountData.access_token === '') {
          this.errorTitleValidationSnackbar = true
        } else {
          this.isLoadingChatwoot = true
          this.chatwootAccountData.sub_id = this.user.sub_id
          const data = this.chatwootAccountData
          const response = await axios.post(`${process.env.VUE_APP_WA_SERVICES_URL}chatwoot/account`, data)
          this.chatwootDialog = false

          this.chatwootAccountData = {}
          location.reload()
        }
      } catch (error) {
        this.errorChatwootValidationSnackbar = true

        // console.log(error)
      }
    },
    async handleConnectInstagram(instance) {
      //console.log(instance, '<<<< INI INSTANCE')
      const response = await fetch(
        `https://graph.facebook.com/v14.0/${instance.instagram_business_account.id}?fields=id,username,name&access_token=${instance.access_token.access_token}`,
      )
      if (response.status === 200) {
        const data = await response.json()
        this.handleAddInstanceInstagram(instance, data)
      }

      // window.FB.api(`/${instance.instagram_business_account.id}?fields=id,username,name`, async function (res) {
      //   //console.log(res, 'INI HANDLE CONNECT INSTAGRAM')
      // })
    },
    isInstagramConnected(instance) {
      //console.log('HEY HEY HEY')
      const findIndex = this.instances.value.findIndex(function (item) {
        return item.phone_number == instance.instagram_business_account.id
      })

      if (findIndex === -1) {
        return true
      }

      return false
    },
    async handleAddInstanceInstagram(facebookInstance, data) {
      let color = Math.floor(Math.random() * 16777215).toString(16)
      while (color.length < 6) {
        color = Math.floor(Math.random() * 16777215).toString(16)
      }

      var findIndex = this.instances.value.findIndex(function (item) {
        return item.phone_number == data.id
      })

      //console.log(findIndex)

      let instance_id

      if (findIndex === -1) {
        instance_id = this.$nanoid().toLowerCase()
      } else {
        instance_id = this.instances.value[findIndex]._id
        this.instances.value.splice(findIndex, 1)
      }

      let instancePayload = {
        _id: instance_id,
        instance_id,
        sub_id: this.user.sub_id,
        label: data.name,
        color: '#' + color,
        sync_contact: false,
        setting_sync: false,
        phone_number: data.id,
        status: 1,
        is_loading: 0,
        label_server: data.name,
        instagram_username: data.username,
        connected_page: facebookInstance.id_page,
        id_facebook: facebookInstance.id_facebook,
        id_instagram: data.id,
        pagesAccessToken: facebookInstance.pagesAccessToken,
        access_token: facebookInstance.access_token,
      }

      this.instances.value.push(instancePayload)

      this.$store.commit('auth/setListInstance', this.instances)

      this.$store.dispatch('global/updateSettingsByKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
        key: 'instances',
        value: this.instances.value,
      })

      this.dialogAddInstance = false
      this.currentTab = 4
    },
    async openDialogInstagram() {
      this.dialogAddInstance = true
      this.currentTab2 = 4
    },
    openDialogEditTokopediaInstance(data) {
      this.dialogEditInstanceTokped = true
      this.tokopediaEditPayload = JSON.parse(JSON.stringify(data))
    },
    closeDialogEditTokped() {
      this.dialogAddInstance = false
      this.dialogEditInstanceTokped = false
      this.tokopediaEditPayload = {
        clientSecret: '',
        clientId: '',
        fs_id: '',
        shop_id: '',
        color: '',
        label: '',
      }
    },
    async saveTokopediaEdit() {
      const updateData = JSON.parse(JSON.stringify(this.tokopediaEditPayload))
      if (!updateData.color) {
        updateData.color = '#' + Math.floor(Math.random() * 16777215).toString(16)
      }
      const payloadUpdate = {
        sub_id: this.user.sub_id,
        clientSecret: updateData.clientSecret,
        clientId: updateData.clientId,
        fs_id: updateData.fs_id,
        shop_id: updateData.shop_id,
        _id: `shop-${this.user.sub_id}-${updateData.shop_id}`,
      }
      const res = await this.$store.dispatch('auth/updateTokopediaShop', payloadUpdate)

      if (res.status) {
        const fetchRoom = await this.$store.dispatch('auth/getTokopediaRoom', payloadUpdate)
        if (fetchRoom.status) {
          var index = this.instances.value.findIndex(function (item) {
            return item.instance_id == updateData._id
          })

          if (index !== -1) {
            this.loadingTable = true
            this.instances.value.splice(index, 1, this.tokopediaEditPayload)
            this.$store.commit('auth/setListInstance', this.instances)
            // }
            this.dialogEditInstanceTokped = false
            this.dialogEditInstance = false
            this.closeDialogAddTokped()
            await this.updateInstances(updateData._id)
          }
        }
      }
    },
    closeDialogAddTokped() {
      this.dialogAddInstance = false
      this.dialogAddInstanceTokped = false
      this.tokopediaAddPayload = {
        clientSecret: '',
        clientId: '',
        fs_id: '',
        shop_id: '',
      }
    },
    async saveFacebookPage(page_name, id_page, access_token) {
      let color = Math.floor(Math.random() * 16777215).toString(16)

      while (color.length < 6) {
        color = Math.floor(Math.random() * 16777215).toString(16)
      }
      // this.tokopediaAddPayload.sub_id = this.user.sub_id

      // this.loadingTable = true
      // this.dialogAddInstanceTokped = false
      // this.dialogAddInstance = false
      // //console.log(this.tokopediaAddPayload, `INI PAYLOAD`)
      // const res = await this.$store.dispatch('auth/registerTokopediaShop', this.tokopediaAddPayload)
      // //console.log(res, `INI RES`)
      // if (res.status) {

      await this.updateInstances()
      // const addPayload = { ...this.tokopediaAddPayload, instance_id }
      // await this.$store.dispatch('auth/getTokopediaRoom', addPayload)
      // }
      // this.loadingTable = false
      // this.tokopediaAddPayload = {
      //   clientSecret: '',
      //   clientId: '',
      //   fs_id: '',
      //   shop_id: '',
      // }
    },
    async saveTokopediaAdd() {
      let color = Math.floor(Math.random() * 16777215).toString(16)

      while (color.length < 6) {
        color = Math.floor(Math.random() * 16777215).toString(16)
      }
      this.tokopediaAddPayload.sub_id = this.user.sub_id

      this.loadingTable = true
      this.dialogAddInstanceTokped = false
      this.dialogAddInstance = false
      //console.log(this.tokopediaAddPayload, `INI PAYLOAD`)
      const res = await this.$store.dispatch('auth/registerTokopediaShop', this.tokopediaAddPayload)
      //console.log(res, `INI RES`)
      if (res.status) {
        const instance_id = this.$nanoid().toLowerCase()
        const addInstancePayload = {
          _id: instance_id,
          instance_id,
          sub_id: this.user.sub_id,
          label: res?.data?.shop_name,
          color: '#' + color,
          sync_contact: true,
          setting_sync: false,
          phone_number: res?.data?.shop_id,
          status: 1,
          is_loading: 0,
          label_server: res?.data?.shop_name,
          shop_name: res?.data?.shop_name,
          clientSecret: this.tokopediaAddPayload.clientSecret,
          clientId: this.tokopediaAddPayload.clientId,
          fs_id: this.tokopediaAddPayload.fs_id,
          shop_id: res?.data?.shop_id,
        }

        this.instances.value.push(addInstancePayload)
        this.$store.commit('auth/setListInstance', this.instances)
        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'instances',
          value: this.instances.value,
        })
        const addPayload = { ...this.tokopediaAddPayload, instance_id }
        await this.$store.dispatch('auth/getTokopediaRoom', addPayload)
      }
      this.loadingTable = false
      this.tokopediaAddPayload = {
        clientSecret: '',
        clientId: '',
        fs_id: '',
        shop_id: '',
      }
    },
    async checkInstances() {
      // if (this.instancesSetting) {
      let isChange = false
      // //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1433 ~ checkInstances ~ isChange', isChange)
      if (this.instances) {
        for (var data of this.instances.value) {
          if (
            data.phone_number &&
            !data.hasOwnProperty('clientSecret') &&
            !data.hasOwnProperty('id_page') &&
            !data.hasOwnProperty('id_instagram') &&
            !data.hasOwnProperty('username') &&
            !data.hasOwnProperty('phone_number_id')
          ) {
            const isReady = await this.$store.dispatch('auth/checkChannelReady', data.sub_id + '-' + data.instance_id)
            //console.log(isReady, 'ini ready bro')
            if (!isReady) {
              await this.$store.dispatch('auth/checkStatusMd', {
                id: data.sub_id + '-' + data.instance_id,
                ready: isReady,
              })

              data.status = this.$store.getters['auth/getStatusInstance']
              isChange = true
            } else {
              await this.$store.dispatch('auth/checkStatusMd', {
                id: data.sub_id + '-' + data.instance_id,
                ready: isReady,
              })
              //console.log('check status channel', data.sub_id + '-' + data.instance_id)
              if (!data.label || data.label == '-') {
                data.label = data.label_server
                  ? data.label_server
                  : this.$store.getters['auth/getInstanceName']
                  ? this.$store.getters['auth/getInstanceName']
                  : data.phone_number
                isChange = true
              }
              // if (!data.status || data.status !== this.$store.getters['auth/getStatusInstance']) {
              data.status = this.$store.getters['auth/getStatusInstance']
              isChange = true
              // }
            }
            // this.syncContactsWa(data)
          }
        }

        // //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1475 ~ checkInstances ~ isChange', isChange)
        if (
          this.instanceValues &&
          this.instanceValues.value.filter(x => (x.status === 0 || x.status === 3) && x.phone_number).length > 0
        ) {
          this.$store.dispatch('global/setStatusChannels', true)
        } else {
          this.$store.dispatch('global/setStatusChannels', false)
          this.$store.commit('inbox/setBannerAlertText', ``)
        }
      }
      if (isChange) {
        // //console.log(this.instanceValues)
        this.instances.value.forEach(async x => {
          if (
            x.status === 3 &&
            this.instances.value.length >= this.getIframeSrcCount &&
            !x.hasOwnProperty('clientSecret') &&
            !x.hasOwnProperty('id_page') &&
            !x.hasOwnProperty('id_instagram') &&
            !data.hasOwnProperty('username')
          ) {
            // await this.getIframeSrc(x.sub_id + '-' + x.instance_id)
            this.getIframeSrcCount += 1
          }
        })
        // this.instances = this.instanceValues
        //console.log('🚀 ~ file: ChannelsMD.vue ~ line 1805 ~ checkInstances ~ this.instances', this.instances)
        this.$store.commit('auth/setListInstance', this.instances)
      }
      // }
    },
    openDisconnected(channel) {
      //console.log(channel, 'ini dDisconnected ')
      const logoutFb = this.logOutWithFacebook

      this.$refs.confirm
        .open(
          'Disconnect',
          `${this.$t('disconnectedConfirmation')} ${
            (channel.label && channel.label.startsWith('-')) ||
            channel.label.includes('Default') ||
            channel.label.includes('62')
              ? channel.label_server
              : channel.label
          } ?`,
          { color: 'red' },
          'Yes',
        )
        .then(async agree => {
          if (agree) {
            this.loading = true
            if (channel.id_page) {
              logoutFb()
            } else {
              try {
                try {
                  await this.$store.dispatch('auth/deleteInstance', this.user.sub_id + '-' + channel._id)
                } catch (err) {
                  console.log(err, 'err 1')
                }
                // await this.$store.dispatch('rooms/deleteChannels', {
                //   token: this.user.token,
                //   instance_id: channel.instance_id,
                //   sub_id: this.user.sub_id,
                // })

                const instancePocket = await clientPocketBase.collection('crm_instances').getList(1, 30, {
                  filter: `_id = '${channel._id}'`,
                })
                //console.log(instancePocket, 'ini dia pockets')

                if (instancePocket && instancePocket.items.length > 0) {
                  try {
                    let a = await clientPocketBase.collection('crm_instances').update(instancePocket.items[0].id, {
                      status: '0',
                      label: '',
                      label_server: '',
                      phone_number: '',
                    })
                    // //console.log(a, 'ini abs')
                    // let rooms
                    // try {
                    //   //console.log(this.user, '<<<user channel')
                    //   rooms = await clientPocketBase.collection('crm_instances').getFullList(1000, {
                    //     filter: `_id = '${channel._id}'`,
                    //   })
                    //   for (let i = 0; i < rooms.length; i++) {
                    //     const { id } = rooms[i]
                    //     await clientPocketBase.collection('crm_contact').delete(id)
                    //   }
                    // } catch (err) {
                    //   console.log(err)
                    // }
                  } catch (err) {
                    console.log(err, 'failed delete')
                  }
                }
              } catch (e) {
                //console.log(e)
              }

              try {
                await this.$store.commit('auth/setIsResetChannels', true)
                await this.$store.dispatch('rooms/resetChannels', {
                  token: this.user.token,
                  instance_id: channel.instance_id,
                  sub_id: this.user.sub_id,
                })
              } catch (err) {
                console.log(err)
              }
            }

            this.loading = false
          }
        })
        .catch(err => {
          console.log(err, 'err disconne')
        })
    },
    overTwoMinutes(loading) {
      if (loading === false) return false
      if (loading === true) return true
      if (loading === 0) return false
      return !(this.$moment() > this.$moment(loading).add(20, 'seconds'))
    },
    async changeValueMute() {
      this.switchMute = true
      const temp = this.muteNotificationSetting.value
      this.muteNotificationSetting.value = this.muteNotification
      await this.updateSettings(this.muteNotificationSetting, async x => {
        if (x === false) {
          this.muteNotification = !this.muteNotification
        }
        this.$store.dispatch('global/setMuteValue', this.muteNotification)
        this.switchMute = false
      })
    },
    async changeValueBold() {
      this.switchBold = true
      this.boldUnrepliedSetting.value = this.boldUnreplied
      await this.updateSettings(this.boldUnrepliedSetting, async x => {
        if (x === false) {
          this.boldUnreplied = !this.boldUnreplied
        }
        this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
        this.switchBold = false
      })
    },
    openDialogDeleteInstance(item) {
      //console.log(item, '<DIALOG DELETE')
      //console.log('dalam dialog delete instance :', item)
      this.dialogDeleteInstance = true
      this.idDeleteInstance = item
    },
    showAlert(warningText) {
      this.alertText = warningText
      this.dialogAlert = true
    },
    async changeWorkspace(data) {
      if (data.sub_id != this.user.sub_id) {
        await this.$store.commit('auth/setUserObj', {
          key: 'sub_id',
          payload: data.sub_id,
        })

        await this.$store.commit('auth/setUserObj', {
          key: 'token',
          payload: data.token,
        })

        await this.$store.commit('auth/setUserObj', {
          key: 'role',
          payload: data.role,
        })

        await new PouchDB('db_message_templates').destroy()
        await new PouchDB('db_settings').destroy()
        await new PouchDB('db_contacts').destroy()
        await new PouchDB('db_rooms').destroy()
        await new PouchDB('db_instances').destroy()
        await new PouchDB('db_broadcasts').destroy()
        await new PouchDB('db_history').destroy()
        await new PouchDB('db_chat').destroy()

        // REMOVE VUEX STORE
        await this.$store.dispatch('global/setContacts', [])
        await this.$store.dispatch('global/setSettings', [])
        await this.$store.dispatch('global/setRooms', null)
        await this.$store.dispatch('global/setFilterChat', null)
        await this.$store.dispatch('global/setLastSeq', 0)
        await this.$store.dispatch('global/setStatusChannels', false)
        this.$store.commit('auth/setListInstance', [])
        await this.checkReplicateContacts(true)

        if (this.instances) {
          if (!this.instances.value) {
            this.instances.value = []
          }

          this.instances.value.forEach(async instance => {
            if (instance.status == 0) {
              await this.expandInstances(instance)
            }
          })
        }

        this.dataWorkspaces.forEach(el => {
          if (el.sub_id === this.user.sub_id) {
            this.activeWorkspace = el
          }
        })

        await this.$store.dispatch('auth/getChannelAccess', {
          sub_id: this.user.sub_id,
          email: this.user.email,
          token: this.user.token,
        })

        setTimeout(function () {
          window.location.reload()
        }, 1000)
      }

      this.$router.go()
    },
    async getSettings() {
      // if (fromServer) {
      const items = await this.loadSettings()
      var res = await this.mappingSettingChannels(items)

      if (this.instances) {
        // //console.log(this.getGlobalChannel, `INI GLOBAL CHANNEL`)
        // //console.log(`INI INSTANCE`, res.instances)
        // this.instances = res.instances
        // this.$store.commit('auth/setListInstance', res.instances)
        if (this.getGlobalChannel) {
          const currentChannel = this.instances.value.find(el => el._id === this.getGlobalChannel.value.instance_id)
          if (currentChannel) {
            this.$store.commit('global/setGlobalChannel', {
              label:
                currentChannel.label && currentChannel.label === '-'
                  ? currentChannel.label_server
                  : currentChannel.label,
              value: currentChannel,
            })
          }
        }
      }

      if (res.syncSetting) {
        this.syncSetting = res.syncSetting
      }

      if (res.muteNotificationSetting) {
        this.muteNotificationSetting = res.muteNotificationSetting
        this.muteNotification = this.muteNotificationSetting.value
        this.$store.dispatch('global/setMuteValue', this.muteNotification)
      } else {
        await this.updateSettings(
          { _id: 'mute_notification', key: 'mute_notification', value: this.muteNotification },
          async x => {
            this.muteNotificationSetting = await this.loadSettingsById(x.id)
            this.muteNotification = this.muteNotificationSetting.value
            this.$store.dispatch('global/setMuteValue', this.muteNotification)
          },
        )
      }

      if (res.boldUnrepliedSetting) {
        this.boldUnrepliedSetting = res.boldUnrepliedSetting
        this.boldUnreplied = this.boldUnrepliedSetting.value
        this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
      } else {
        await this.updateSettings(
          { _id: 'bold_unreplied', key: 'bold_unreplied', value: this.boldUnreplied },
          async x => {
            this.boldUnrepliedSetting = await this.loadSettingsById(x.id)
            this.boldUnreplied = this.boldUnrepliedSetting.value
            this.$store.dispatch('global/setBoldUnreplied', this.boldUnreplied)
          },
        )
      }
    },
    async deleteInstances(instance) {
      console.log(this.currentTab, this.curentTab2, '<<<vira')
      this.dialogDeleteInstance = false
      this.isLoadingChatwoot = true
      setTimeout(() => {
        this.isLoadingChatwoot = false
      }, 5000)
      const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/delete-instance`
      if (this.currentTab2 === 2 && this.currentTab === 2) {
        try {
          await clientPocketBase.collection('crm_instances').delete(instance.id)
          const body = {
            sub_id: this.user.sub_id,
            instance,
          }
          await axios.post(endpoint, body)
        } catch (error) {
          console.log(error, 'wui')
        }

        this.fetchBcgroupInstance()
      }
      if (this.currentTab === 2 && this.currentTab2 === 0) {
        await clientPocketBase.collection('crm_contact_group').delete(instance.id)
        for (let i = 0; i < instance.instances.length; i++) {
          const deleteInstance = instance.instances[i]
          try {
            await clientPocketBase.collection('crm_instances').delete(deleteInstance)
            const endpoint = `${process.env.VUE_APP_WA_SERVICES_URL}chats/delete-instance`
            const body = {
              sub_id: this.user.sub_id,
              deleteInstance,
            }
            await axios.post(endpoint, body)
          } catch (err) {
            console.log(err)
          }
        }
      }
      var self = this
      if (instance.hasOwnProperty('clientSecret')) {
        const payloadDelete = {
          sub_id: this.user.sub_id,
          id: `shop-${this.user.sub_id}-${instance.shop_id}`,
          shop_id: instance.shop_id.toString(),
        }
        const res = await this.$store.dispatch('auth/deleteTokopediaShop', payloadDelete)
        // if (res.status) {
        var index = this.instances.value.findIndex(function (item) {
          return item._id == instance._id
        })

        // if (index == -1) {
        //   index = 0
        // }

        if (index !== -1) {
          this.instances.value.splice(index, 1)
          // this.$store.commit('auth/setListInstance', this.instances)
          // }

          await self.updateInstances(instance._id)
        }
      } else if (instance.hasOwnProperty('id_page')) {
        var index = this.instances.value.findIndex(function (item) {
          return item._id == instance._id
        })

        if (index !== -1) {
          this.instances.value.splice(index, 1)

          this.$store.commit('auth/setListInstance', this.instances)

          await this.$store.dispatch('global/updateSettingsByKey', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            key: 'instances',
            value: this.instances.value,
          })

          await this.$store.dispatch('rooms/deleteChannels', {
            token: this.user.token,
            instance_id: instance._id,
            sub_id: this.user.sub_id,
          })

          await this.updateInstances(instance._id)
        }
      } else if (instance.hasOwnProperty('id_instagram')) {
        var index = this.instances.value.findIndex(function (item) {
          return item._id == instance._id
        })

        if (index !== -1) {
          this.instances.value.splice(index, 1)

          this.$store.commit('auth/setListInstance', this.instances)

          await this.$store.dispatch('global/updateSettingsByKey', {
            sub_id: this.user.sub_id,
            token: this.user.token,
            key: 'instances',
            value: this.instances.value,
          })

          await this.$store.dispatch('rooms/deleteChannels', {
            token: this.user.token,
            instance_id: instance._id,
            sub_id: this.user.sub_id,
          })

          await this.updateInstances(instance._id)
        }
      } else if (instance.hasOwnProperty('username') && !instance.hasOwnProperty('profileKey')) {
        // delete from twitter
        const baseUrl = `${process.env.VUE_APP_DB_API_URL}`
        const response = await axios.delete(`${baseUrl}superwa/couchdb/twitter/subscriptions/${this.user.sub_id}`)
        //console.log('response :', response)
        // delete from database and update settings
        var index = this.instances.value.findIndex(function (item) {
          return item._id == instance._id
        })
        if (index !== -1) {
          this.instances.value.splice(index, 1)
          // this.$store.commit('auth/setListInstance', this.instances)
        }
        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'instances',
          value: this.instances.value,
        })
        await this.$store.dispatch('rooms/deleteChannels', {
          token: this.user.token,
          instance_id: instance._id,
          sub_id: this.user.sub_id,
        })

        // const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
        //   sub_id: this.user.sub_id,
        //   token: this.user.token,
        //   key: 'instances',
        // })

        // if (instancePg.status) {
        //   this.instances = instancePg.data
        // }
        const listInstances = await clientPocketBase.collection('crm_instances').getFullList(20, {
          filter: `sub_id = '${this.user.sub_id}'`,
        })

        if (listInstances && listInstances.length > 0) {
          this.instances.value = listInstances
        }
      } else if (instance.hasOwnProperty('phone_number_id')) {
        const response = await axios.delete(
          `${process.env.VUE_APP_DB_API_URL}superwa/couchdb/wa-cloud/subscription/${this.user.sub_id}`,
        )

        // delete from database and update settings
        const index = this.instances.value.findIndex(item => {
          return item._id == instance._id
        })

        if (index !== -1) {
          this.instances.value.splice(index, 1)
          // this.$store.commit('auth/setListInstance', this.instances)
        }

        await this.$store.dispatch('global/updateSettingsByKey', {
          sub_id: this.user.sub_id,
          token: this.user.token,
          key: 'instances',
          value: this.instances.value,
        })

        await this.$store.dispatch('rooms/deleteChannels', {
          token: this.user.token,
          instance_id: instance._id,
          sub_id: this.user.sub_id,
        })

        // const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
        //   sub_id: this.user.sub_id,
        //   token: this.user.token,
        //   key: 'instances',
        // })

        // if (instancePg.status) {
        //   this.instances = instancePg.data
        // }
        const listInstances = await clientPocketBase.collection('crm_instances').getFullList(20, {
          filter: `sub_id = '${this.user.sub_id}'`,
        })

        if (listInstances && listInstances.length > 0) {
          this.instances.value = listInstances
        }
      } else if (instance.hasOwnProperty('profileKey')) {
        await this.$store.dispatch('global/unlinkSosmed', {
          profileKey: this.activeUserAyrshare.profileKey,
          platform: instance.label_server,
        })
        await fetch('https://app.ayrshare.com/api/profiles', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${API_KEY}`,
          },
        })
          .then(res => res.json())
          .then(users => {
            this.lengthProfileAyrshare = users.profiles.lengthProfileAyrshare
            users.profiles.forEach(user => {
              if (
                user.refId === instance.refId &&
                user?.activeSocialAccounts !== this.activeUserAyrshare.activeSocialAccounts
              ) {
                //console.log(this.activeUserAyrshare, 'this.activeUserAyrsharethis.activeUserAyrshare')
                if (
                  this.activeUserAyrshare.activeSocialAccounts.length == 1 ||
                  this.activeUserAyrshare.activeSocialAccounts.length == 0
                ) {
                  this.$store.dispatch('global/deleteProfileOnAyrshare', {
                    profileKey: this.activeUserAyrshare.profileKey,
                  })
                  // delete from database and update settings
                  const index = this.instances.value.findIndex(item => {
                    return item.refId == user.refId
                  })
                  if (index !== -1) {
                    this.instances.value.splice(index, 1)
                    // this.$store.commit('auth/setListInstance', this.instances)
                  }

                  this.$store.dispatch('global/updateSettingsByKey', {
                    sub_id: this.user.sub_id,
                    token: this.user.token,
                    key: 'instances',
                    value: this.instances.value,
                  })

                  // const instancePg = this.$store.dispatch('global/getSettingsByKey', {
                  //   sub_id: this.user.sub_id,
                  //   token: this.user.token,
                  //   key: 'instances',
                  // })

                  // if (instancePg.status) {
                  //   this.instances = instancePg.data
                  // }
                  const listInstances = clientPocketBase.collection('crm_instances').getFullList(20, {
                    filter: `sub_id = '${this.user.sub_id}'`,
                  })

                  if (listInstances && listInstances.length > 0) {
                    this.instances.value = listInstances
                  }
                  this.$store.dispatch('global/deleteProfileAyrshare', {
                    token: this.user.token,
                    profileKey: this.activeUserAyrshare.profileKey,
                  })
                  //console.log(this.instances.value, 'this.instances.value2')
                  this.activeUserAyrshare = false
                } else {
                  const formattedUser = {
                    profileKey: this.activeUserAyrshare.profileKey,
                    activeSocialAccounts: user.activeSocialAccounts,
                    refId: user.refId,
                    title: user.title,
                  }
                  this.updateInstanceAyrshare(formattedUser)
                }
              }
            })
          })
          .catch(console.error)
      } else {
        var index = this.instances.value.findIndex(function (item) {
          return item._id == instance._id
        })

        // if (index == -1) {
        //   index = 0
        // }

        this.loadingTable = false

        if (index !== -1) {
          const instanceId = this.instances.value[index]._id
          await this.$store.dispatch('rooms/deleteChannels', {
            token: this.user.token,
            instance_id: this.instances.value[index]._id,
            sub_id: this.user.sub_id,
          })
          try {
            await this.$store.dispatch('auth/deleteInstance', this.user.sub_id + '-' + instanceId)
          } catch (err) {}
          this.instances.value.splice(index, 1)

          const listInstances = await clientPocketBase.collection('crm_instances').getFullList(20, {
            filter: `_id = '${instance._id}'`,
            $autoCancel: false,
          })

          if (listInstances && listInstances.length > 0) {
            try {
              // await clientPocketBase.collection('crm_instances').delete(listInstances[0].id, { $autoCancel: false })
            } catch (error) {}
            try {
              // await axios.post(`${process.env.VUE_APP_POCKETBASE_URL}/q`, {
              //   q: `DELETE FROM "crm_contact" WHERE "instance" = "${listInstances[0].id}"`,
              // })
              // const body = {
              //   sub_id: this.user.sub_id,
              //   instance: this.instances.value,
              // }
              // await axios.post(endpoint, body)
            } catch (error) {
              console.log(error)
            }
          }
          this.$store.commit('auth/setListInstance', this.instances)

          await this.$store.dispatch('auth/deleteChats', this.user.sub_id + '-' + instanceId)
        }
      }
    },
    async updateInstances(id = null) {
      await this.updateSettings(this.instances, async success => {
        this.loadingTable = true
        await this.getSettings(true)
        this.loadingTable = false
        // this.$store.commit('auth/setListInstance', this.instances.value)
        if (id !== null) {
          // await this.$store.dispatch('auth/deleteChannelMd', this.user.sub_id + '-' + id)
          await this.$store.dispatch('auth/deleteInstance', this.user.sub_id + '-' + id)
          await this.$store.dispatch('auth/deleteChats', this.user.sub_id + '-' + id)
        }
      })
    },
    openDialogEditInstances(data) {
      if (data.hasOwnProperty('clientSecret')) {
        this.openDialogEditTokopediaInstance(data)
      } else if (this.currentTab === 2) {
        this.isNewModeInstances = false
        this.instanceName = data.group_name
        this.instanceColor = data.color
        this.instanceSyncContact = data.sync_contact
        this.instanceSettingSync = data.setting_sync ? data.setting_sync : false
        this.selectedInstance = data
        this.dialogEditInstances = true
      } else {
        this.isNewModeInstances = false
        this.instanceName =
          (data.label && data.label.startsWith('-')) || data.label.includes('Default') || data.label.includes('62')
            ? data.label_server
            : data.label
        this.instanceColor = data.color
        this.instanceSyncContact = data.sync_contact
        this.instanceSettingSync = data.setting_sync ? data.setting_sync : false
        this.selectedInstance = data
        this.dialogEditInstances = true
      }
    },
    // async openDialogAddInstance() {
    //   this.dialogAddInstance = true
    //   await this.createInstances()
    // },
    async openAddInstanceDialog() {
      //console.log('current tab 2 :', this.currentTab2)
      //console.log('current tab :', this.currentTab)
      const index = this.currentTab
      console.log('index :', index)
      this.currentTab2 = index
      if (this.currentTab2 === 0 && this.currentTab === 0) {
        this.dialogAddInstance = true
      }
      if (this.currentTab2 === 1 && this.currentTab === 1) {
        this.chatwootDialog = true
      }

      if (this.currentTab2 === 2 && this.currentTab === 2) {
        this.bcGroupDialog = true
      }

      await this.createInstances()
    },
    async createInstances() {
      const instancesId = 's' + this.$nanoid().toLowerCase()
      // let limitOps = await this.$store.dispatch('subscriptions/getSubscriptionInfo', {
      //   activeWorkspace: this.activeWorkspace,
      //   email: this.user.email,
      // })
      // //console.log(limitOps, 'aaaaaaaaaaaaaaaaaaaaaaaaaaa')
      let color = Math.floor(Math.random() * 16777215).toString(16)

      while (color.length < 6) {
        color = Math.floor(Math.random() * 16777215).toString(16)
      }
      //console.log(this.instances, 'ini wuwo')

      this.isNewModeInstances = true
      this.instanceName = '-'
      this.instanceSyncContact = true
      this.instanceSettingSync = false
      this.createLocalInstance = {
        is_adding: true,
        _id: instancesId,
        instance_id: instancesId,
        sub_id: this.user.sub_id,
        label: this.instanceName,
        color: '#' + color,
        sync_contact: this.instanceSyncContact,
        setting_sync: this.instanceSettingSync,
        phone_number: '',
        status: 0,
        is_loading: true,
        broadcast_group: this.currentTab2 === 2 ? true : false,
      }
      // await this.initWa(this.user.sub_id, this.createLocalInstance.instance_id)
      this.startCheckStatus(this.user.sub_id, this.createLocalInstance.instance_id, true)
      this.openInstance = this.createLocalInstance
    },
    async findLabelName(id) {
      try {
        const labelName = await clientPocketBase.collection('crm_instance_label_names').getOne(`${id}`)
        return labelName
      } catch (error) {
        console.error(error, 'failed get label name')
      }
    },

    async saveInstancesEdit() {
      if (this.instanceName && this.instances) {
        this.dialogEditInstances = false
        this.dialogAddInstance = false

        this.loading = true
        this.selectedInstance.label = this.instanceName
        this.selectedInstance.color = this.instanceColor
        if (!this.selectedInstance.color) {
          this.selectedInstance.color = '#' + Math.floor(Math.random() * 16777215).toString(16)
        }
        //console.log(this.currentTab, 'ini viraa')
        if (this.currentTab === 1) {
          // const index = this.instances.value.findIndex(
          //   instance => instance.profileKey === this.selectedInstance.profileKey,
          // )
          // if (index !== -1) {
          //   this.instances.value[index].activeSocialAccounts = this.instances.value[index].activeSocialAccounts.filter(
          //     el => {
          //       return (el.sosmed_id = this.selectedInstance.sosmed_id)
          //     },
          //   )
          // await this.$store.dispatch('global/updateSettingsByKey', {
          //   sub_id: this.user.sub_id,
          //   token: this.user.token,
          //   key: 'instances',
          //   value: this.instances.value,
          // })
          // const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
          //   sub_id: this.user.sub_id,
          //   token: this.user.token,
          //   key: 'instances',
          // })

          // if (instancePg.status) {
          //   console.log(this.$store.getters['auth/getListInstance'].value, 'ini sebelum chan')
          //   this.instances = instancePg.data
          // }

          try {
            const labelId = this.$Md5Hash(this.user.sub_id + this.selectedInstance.phone_number)
            const labelName = await this.findLabelName(labelId)
            let isLabelExists = false

            if (labelName) {
              if (Object.keys(labelName).length) {
                isLabelExists = true
              }
            }

            if (isLabelExists) {
              await clientPocketBase
                .collection('crm_instance_label_names')
                .update(`${this.$Md5Hash(this.user.sub_id + this.selectedInstance.phone_number)}`, {
                  edited_name: this.instanceName || this.selectedInstance.label,
                })
            } else {
              await clientPocketBase.collection('crm_instance_label_names').create({
                id: labelId,
                sub_id: this.user.sub_id,
                edited_name: this.instanceName || this.selectedInstance.label,
                phone_number: this.selectedInstance.phone_number,
              })
            }
          } catch (error) {
            console.error(error, 'error update instance label name')
          }

          try {
            await clientPocketBase.collection('crm_instances').update(this.selectedInstance.id, {
              label: this.instanceName || this.selectedInstance.label,
              label_server: this.instanceName || this.selectedInstance.label,
              color: this.instanceColor ? this.instanceColor : this.selectedInstance.color,
              instance_label_name: this.$Md5Hash(this.user.sub_id + this.selectedInstance.phone_number),
            })
          } catch (err) {
            console.log(err, 'err update')
          }

          // di komen karna ddirasa tiak perlu + ngebuat bug nama channel jd ke reset
          // const listInstances = await clientPocketBase.collection('crm_instances').getFullList(20, {
          //   filter: `sub_id = '${this.user.sub_id}'`,
            
          // })

          // if (listInstances && listInstances.length > 0) {
          //   this.instances.value = listInstances
          // }
        } else if (this.currentTab === 2) {
          try {
            await clientPocketBase.collection('crm_contact_group').update(this.selectedInstance.id, {
              group_name: this.instanceName || this.selectedInstance.group_name,
              color: this.instanceColor ? this.instanceColor : this.selectedInstance.color,
            })
          } catch (err) {
            console.log(err, 'err update')
          }

          const listInstances = await clientPocketBase.collection('crm_instances').getFullList(20, {
            filter: `sub_id = '${this.user.sub_id}'`,
          })

          if (listInstances && listInstances.length > 0) {
            this.instances.value = listInstances
          }
        } else {
          const index = this.instances.value.findIndex(instance => instance._id === this.selectedInstance._id)
          if (index !== -1) {
            this.instances.value[index] = this.selectedInstance
            // try {
            //   await db.showedRooms
            //     .where({ instance_id: this.instances.value[index].instance_id })
            //     .modify({ instance: this.instances.value[index] })
            // } catch (err) {
            //   console.log(err)
            // }

            // //console.log(true, this.selectedInstance, this.instanceName, this.instanceColor, `INI DI INSTANCE EDIT`)

            // await this.$store.dispatch('global/updateSettingsByKey', {
            //   sub_id: this.user.sub_id,
            //   token: this.user.token,
            //   key: 'instances',
            //   value: this.instances.value,
            // })
            // const instancePg = await this.$store.dispatch('global/getSettingsByKey', {
            //   sub_id: this.user.sub_id,
            //   token: this.user.token,
            //   key: 'instances',
            // })

            // if (instancePg.status) {
            //   this.instances = instancePg.data
            //   // let selInstance = this.instances.value.find(el => el._id === this.selectedInstance._id)
            //   // let a = await db.showedRooms.where('instance_id').equals(this.selectedInstance._id).modify({instance: selInstance})
            // }

            //console.log(this.selectedInstance, 'ini selected')

            // disini nyund
            try {
              const labelId = this.$Md5Hash(this.user.sub_id + this.selectedInstance.phone_number)
              const labelName = await this.findLabelName(labelId)
              let isLabelExists = false

              if (labelName) {
                if (Object.keys(labelName).length) {
                  isLabelExists = true
                }
              }

              if (isLabelExists) {
                await clientPocketBase
                  .collection('crm_instance_label_names')
                  .update(`${this.$Md5Hash(this.user.sub_id + this.selectedInstance.phone_number)}`, {
                    edited_name: this.instanceName || this.selectedInstance.label,
                  })
              } else {
                await clientPocketBase.collection('crm_instance_label_names').create({
                  id: labelId,
                  sub_id: this.user.sub_id,
                  edited_name: this.instanceName || this.selectedInstance.label,
                  phone_number: this.selectedInstance.phone_number,
                })
              }
            } catch (error) {
              console.error(error, 'error update/create instance label name')
            }

            try {
              await clientPocketBase.collection('crm_instances').update(this.selectedInstance.id, {
                label: this.instanceName || this.selectedInstance.label,
                label_server: this.instanceName || this.selectedInstance.label,
                color: this.instanceColor ? this.instanceColor : this.selectedInstance.color,
                instance_label_name: this.$Md5Hash(this.user.sub_id + this.selectedInstance.phone_number),
              })
            } catch (err) {
              console.log(err, 'err update')
            }

            // const listInstances = await clientPocketBase.collection('crm_instances').getFullList(20, {
            //   filter: `sub_id = '${this.user.sub_id}'`,
            // })
            // if (listInstances && listInstances.length > 0) {
            //   this.instances.value = listInstances
            // }
            // await this.updateInstances()
            // const res = await this.$store.dispatch('firestore/editSetting', {
            //   sub_id: this.user.sub_id,
            //   field: 'instances',
            //   value: this.instances.value,
            // })
            // if (res) {
            //   this.$store.commit('auth/setListInstance', this.instances)
            // }
          }
        }

        this.loading = false
      }
    },
    async saveInstances() {
      this.loadingTable = true
      console.log(
        '🚀 ~ file: ChannelsMD.vue ~ line 2880 ~ saveInstances ~ this.instances.value.push',
        this.instances.value,
        this.createLocalInstance,
        this.isNewModeInstances,
      )
      // const nano = 's' + this.$nanoid().toLowerCase()
      // const encoded = window.btoa(nano).replace(/[=]/g, "")
      // const encodedId2 = encoded.substring(encoded.length - 15, encoded.length)
      // const encodedId = window.btoa(nano).replace(/([=])/g, '').slice(-15)

      try {
        if (this.isNewModeInstances) {
          if (this.instances) {
            this.instances.value.push(this.createLocalInstance)
            this.$store.commit('auth/setListInstance', this.instances)

            if (this.instances.value[this.instances.value.length - 1]) {
              await this.expandInstances(this.instances.value[this.instances.value.length - 1])
            }

            // await this.updateInstances()
            // await this.$store.dispatch('global/updateSettingsByKey', {
            //   sub_id: this.user.sub_id,
            //   token: this.user.token,
            //   key: 'instances',
            //   value: this.instances.value,
            // })

            // for (let i = 0; i < this.instances.value.length; i++) {
            //   const instancePckt = this.instances.value[i]
            //   try {
            //     const instancePocket = await clientPocketBase.collection('crm_instances').getList(1, 30, {
            //       filter: `_id = '${instancePckt._id}'`,
            //     })
            //     // const encodedId = window.btoa(instancePckt._id).replace(/([=])/g, '').slice(-15)
            //     const encodedId = this.$Md5Hash(instancePckt._id)
            //     //console.log(encodedId, instancePocket, instancePckt, 'ini dia brok')

            //     if (instancePocket.items && !instancePocket.items.length) {
            //       try {
            //         let createdINs = await clientPocketBase.collection('crm_instances').create({
            //           id: encodedId,
            //           label: instancePckt.label,
            //           phone_number: instancePckt.phone_number,
            //           label_server: instancePckt.label_server,
            //           _id: instancePckt._id,
            //           color: instancePckt.color,
            //           status: instancePckt.status,
            //           sub_id: instancePckt.sub_id,
            //           broadcast_group: this.currentTab2 === 2 ? true : false,
            //         })
            //       } catch (err) {
            //         console.log(err, 'client create error')
            //       }
            //     }
            //   } catch (err) {
            //     console.log(err.data, '')
            //   }
            // }
            this.dialogEditInstances = false
            this.dialogAddInstance = false
            this.isNewModeInstances = false
          }
        } else {
          //console.log(this.instances.value, this.createLocalInstance, 'ini pocketsbz')
          // for (let i = 0; i < this.instances.value.length; i++) {
          //   const instancePckt = this.instances.value[i]
          //   // const instancePocket = await clientPocketBase.collection().getList(1, 30, {
          //   //   filter: `_id = '${instancePckt.instance_id}'`,
          //   // })
          //   // //console.log(instancePocket, instancePckt, 'ini pocketsbzz')
          //   const idInstance = 's' + this.$nanoid().toLowerCase()
          //   //console.log(idInstance, 'ini updatean')
          //   try {
          //     await clientPocketBase.collection().update(instancePckt.id, {
          //       instance_id: idInstance,
          //       color: instancePckt.color,
          //       status: 1,
          //       label: instancePckt.label,
          //       phone_number: instancePckt.phone_number,
          //       label_server: instancePckt.label_server,
          //       _id: idInstance,
          //     })
          //   } catch (err) {
          //     console.log(err, 'client create error')
          //   }
          // }
          if (this.instanceName && this.selectedInstance) {
            this.selectedInstance.label = this.instanceName
            this.selectedInstance.color = this.instanceColor
            this.selectedInstance.sync_contact = this.instanceSyncContact
            this.selectedInstance.setting_sync = this.instanceSettingSync

            if (!this.selectedInstance.color) {
              this.selectedInstance.color = '#' + Math.floor(Math.random() * 16777215).toString(16)
            }

            // await this.updateInstances()
            // await this.$store.dispatch('global/updateSettingsByKey', {
            //   sub_id: this.user.sub_id,
            //   token: this.user.token,
            //   key: 'instances',
            //   value: this.instances.value,
            // })checkinstances()

            this.dialogEditInstances = false
            this.dialogAddInstance = false
          }
        }
      } catch (err) {
        console.log(err, 'ini errnya<<')
      }

      this.dialogAddBcGroup = false

      this.loadingTable = false
      await this.checkInstances()
      if (this.currentTab2 === 2) {
        this.fetchBcgroupInstance()
      }
    },
    openDialogWorkspaces() {
      this.workspaceName = this.getWorkspaceName
      this.$refs.dialogAddListContact.open(this.workspaceName).then(async dialog => {
        if (dialog.status) {
          this.workspaceName = dialog.name
          await this.saveWorkspaces()
        }
      })
    },
    async saveWorkspaces() {
      var res = await this.$store.dispatch('auth/editUser', {
        email: this.user.email,
        sub_id: this.user.sub_id,
        workspace_name: this.workspaceName,
      })

      if (res.status) {
        await this.$store.dispatch('auth/fetchUserWorkspace', {
          email: this.user.email,
        })

        this.dataWorkspaces.forEach(el => {
          if (el._id === this.activeWorkspace._id) {
            this.activeWorkspace = el
            this.activeWorkspace.workspace_name = this.workspaceName
          }
        })
        // this.$store.commit('auth/setSelectedWorkspace', this.getWorkspaceName)
      } else {
        this.$q.dialog({
          title: 'Oops...',
          message: res.errorMessage,
        })
      }

      this.dialogEditWorkspaces = false
    },
    async updateSyncSetting(value) {
      const self = this
      self.syncSetting.value = value
      await this.updateSettings(this.syncSetting)
    },
    async updateSyncContact() {
      await this.updateInstances()
    },
    async receiveMessage(event) {
      const self = this
      // //console.log(event.origin, '======================= event')
      if (
        event.origin === process.env.VUE_APP_WA_SERVICES_URL.substr(0, process.env.VUE_APP_WA_SERVICES_URL.length - 1)
      ) {
        if (event.data.err) {
          //console.log(event.data.err)
        } else {
          this.$store.commit('global/setLoadingMessages', true)
          // //console.log(true, `MASUK ELSE 1`)
          if (event.data.id.split('-').length > 1) {
            const subId = event.data.id.split('-')[0]
            const instanceId = event.data.id.split('-')[1]
            let existPhoneNumber, obj

            if (this.createLocalInstance.is_adding) {
              this.createLocalInstance.phone_number = event.data.no
              existPhoneNumber = self.instances.value.find(instance => {
                return instance.phone_number === event.data.no
              })
              delete this.createLocalInstance.is_adding
              obj = this.createLocalInstance
            } else {
              existPhoneNumber = self.instances.value.find(instance => {
                return instance.phone_number === event.data.no
              })

              obj = self.instances.value.find(instance => {
                return instance.sub_id === subId && instanceId === instance._id
              })
            }

            //console.log(
            //   '🚀 ~ file: ChannelsMD.vue ~ line 3045 ~ receiveMessage ~ existPhoneNumber && obj && existPhoneNumber.instance_id !== obj.instance_id',
            //   existPhoneNumber && obj && existPhoneNumber._id !== obj._id,
            // )
            if (existPhoneNumber && obj && existPhoneNumber._id !== obj._id) {
              this.showAlert('Phone number already in Channel')
              // await this.$store.dispatch('auth/deleteChannelMd', this.user.sub_id + '-' + obj._id)
              await this.$store.dispatch('auth/deleteInstance', this.user.sub_id + '-' + obj._id)

              this.dialogAddInstance = false
              await this.$store.dispatch('auth/deleteChats', this.user.sub_id + '-' + obj._id)

              return
            } else {
              if (obj) {
                // await self.$store.dispatch('auth/checkStatus', subId + '-' + instanceId)

                obj.is_loading = this.$moment().unix()

                if (!obj.label || obj.label == '-') {
                  obj.label = obj.label
                    ? obj.label
                    : obj.label_server
                    ? obj.label_server
                    : self.$store.getters['auth/getInstanceName']
                    ? self.$store.getters['auth/getInstanceName']
                    : event.data.name
                }

                obj.status = 1 //self.$store.getters['auth/getStatusInstance']
                obj.phone_number = event.data.no
                obj.is_loading = this.$moment().unix()

                // self.listInstances.value.push(obj)
                // await self.updateInstances()
              }

              // //console.log(
              //   '🚀 ~ file: ChannelsMD.vue ~ line 2453 ~ receiveMessage ~ this.createLocalInstance',
              //   this.createLocalInstance,
              // )
              this.createLocalInstance = obj
              await this.saveInstances()
              if (this.createLocalInstance.status == 1) {
              }

              // update firestore
              // const res = await this.$store.dispatch('firestore/editSetting', {
              //   sub_id: this.user.sub_id,
              //   field: 'instances',
              //   value: this.listInstances.value,
              // })
              // if (res) {
              //   this.$store.commit('auth/setListInstance', this.listInstances)
              // }

              // reset channel
              // await self.$store.dispatch('auth/resetStatus', subId + '-' + instanceId)

              // fix security chat
              var endpoint = process.env.VUE_APP_DB_API_URL + 'superwa/couchdb/update-database-security'

              var security = {
                members: {
                  names: [self.user.sub_id],
                  roles: ['_admin'],
                },
                admins: {
                  roles: ['_admin'],
                },
              }

              var body = {
                subId: self.user.sub_id,
                security: security,
              }

              await self.$axiosLimit.post(endpoint, body)
            }
            this.$store.commit('global/setCurrentScanned', obj)
          }
        }
      }
    },
    getIframeSrc(data) {
      //console.log(process.env.VUE_APP_WA_SERVICES_URL + data, 'data iframe')
      return process.env.VUE_APP_WA_SERVICES_URL + 'register-channel-pg/' + data
    },
    async expandInstances(data) {
      //console.log('🚀 ~ file: ChannelsMD.vue ~ line 3129 ~ expandInstances ~ data', data)
      // await this.initWa(data.sub_id, data.instance_id)

      this.startCheckStatus(data.sub_id, data._id)

      if (
        this.expanded.find(item => {
          return item === data._id
        })
      ) {
        this.openInstance = null

        var index = this.expanded.findIndex(function (item) {
          return item.id == data._id
        })

        if (index == -1) {
          index = 0
        }

        if (index !== -1) {
          this.expanded.splice(index, 1)
        }
      } else {
        this.openInstance = data
        this.expanded.push(data)
      }
    },
    //Ayrshare start
    async fetchUserProfile() {
      // await fetch('https://app.ayrshare.com/api/profiles', {
      //   method: 'GET',
      //   headers: {
      //     Authorization: `Bearer ${API_KEY}`,
      //   },
      // })
      //   .then(res => res.json())
      //   .then(users => {
      //     //console.log(users, 'users')
      //     this.lengthProfileAyrshare = users.profiles.length
      //   })
      //   .catch(console.error)
    },
    async addSosialMediaInstance() {
      if (this.lengthProfileAyrshare === 5) {
        this.messageshowAlertProfileAyrshare = 'Sorry, you can not connect to your sosmed, the profile is full'
        this.typeDialog = 'error'
        this.dialogAddInstance = false
        this.showAlertProfileAyrshare = true
      } else {
        fetch('https://app.ayrshare.com/api/profiles/profile', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${API_KEY}`,
          },
          body: JSON.stringify({
            title: this.user.email, // required
          }),
        })
          .then(res => res.json())
          .then(json => {
            if (json.status === 'success') {
              this.updateInstanceAyrshare(json)
              this.$store.dispatch('global/createProfileAyrshare', {
                token: this.user.token,
                data: {
                  profileKey: json.profileKey,
                  title: json.title,
                  refId: json.refId,
                  workspaces: this.user.sub_id,
                },
              })
              this.activeUserAyrshare = {
                profileKey: json.profileKey,
                title: json.title,
                refId: json.refId,
                workspaces: this.user.sub_id,
              }
              this.messageshowAlertProfileAyrshare = 'Successfully connect to sosmed'
              this.typeDialog = 'success'
              this.dialogAddInstance = false
              this.showAlertProfileAyrshare = true
              this.generateJWT(this.activeUserAyrshare.profileKey)
            } else if (json.status === 'error' && json.code === 146) {
              this.messageshowAlertProfileAyrshare = 'You have registered before'
              this.typeDialog = 'error'
              this.dialogAddInstance = false
              this.showAlertProfileAyrshare = true
            }
          })
          .catch(console.error)
      }
    },
    async generateJWT(profileAyrshareKey) {
      const {
        data: { marketaKey: privateKey },
      } = await this.$store.dispatch('global/getMarketaPrivateKey', {
        sub_id: this.user.sub_id,
        token: this.user.token,
      })
      await fetch('https://app.ayrshare.com/api/profiles/generateJWT', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${API_KEY}`,
        },
        body: JSON.stringify({
          domain: 'marketa',
          privateKey: privateKey,
          profileKey: profileAyrshareKey,
        }),
      })
        .then(res => res.json())
        .then(json => {
          this.tokenAyrshare = json.token
          this.linkSosmed()
        })
        .catch(console.error)
    },
    linkSosmed() {
      window.open(
        'https://profile.ayrshare.com?domain=marketa&jwt=' +
          this.tokenAyrshare +
          '&redirect=http://localhost:8080/channels&logout=true',
      )
    },
    updateInstanceAyrshare(user) {
      try {
        // delete from database and update settings
        const index = this.instances.value.findIndex(item => {
          return item.profileKey == user.profileKey
        })

        if (index !== -1) {
          this.instances.value.splice(index, 1)
          // this.$store.commit('auth/setListInstance', this.instances)
        }

        const instance_id = this.$nanoid().toLowerCase()
        const payload = {
          _id: instance_id,
          instance_id,
          sub_id: this.user.sub_id,
          profileKey: user.profileKey,
          refId: user.refId,
          label: 'ayrshare-instance',
          label_server: 'ayrshare-instance',
          activeSocialAccounts: user?.activeSocialAccounts?.map(activeSocialAccounts => {
            const sosmed_id = this.$nanoid().toLowerCase()
            let color = Math.floor(Math.random() * 16777215).toString(16)

            while (color.length < 6) {
              color = Math.floor(Math.random() * 16777215).toString(16)
            }
            return {
              _id: sosmed_id,
              sosmed_id,
              label: activeSocialAccounts,
              color: '#' + color,
              sync_contact: true,
              setting_sync: false,
              phone_number: activeSocialAccounts,
              status: 1,
              is_loading: 0,
              label_server: activeSocialAccounts,
              profileKey: user.profileKey,
              refId: user.refId,
            }
          }),
        }
        this.instances.value.push(payload)
        this.$store.commit('auth/setListInstance', this.instances)

        // this.$store.dispatch('global/updateSettingsByKey', {
        //   sub_id: this.user.sub_id,
        //   token: this.user.token,
        //   key: 'instances',
        //   value: this.instances.value,
        // })
      } catch (error) {
        console.log(error)
      }
    },
  },
  watch: {
    dialogAddInstance(newVal, oldVal) {
      //console.log(
      //   '🚀 ~ file: ChannelsMD.vue ~ line 3401 ~ dialogAddInstance ~ !newVal && newVal !== oldVal',
      //   !newVal && newVal !== oldVal,
      // )
      if (!newVal && newVal !== oldVal) {
        if (this.intervalId) clearInterval(this.intervalId)
      }
    },
    bcGroupDialog() {
      if (this.bcGroupDialog === false) {
        this.currentTab2 = 0
      }
    },
  },
  computed: {
    getGlobalChannel() {
      return this.$store.getters['global/getGlobalChannel']
    },
    defaultProfPic() {
      return `${process.env.VUE_APP_WA_API_URL}ppic/@whatsapp.net/2414214242414/a.jpg`
    },
    instanceValues() {
      const instances = this.instances

      if (instances) {
        if (!instances.value) {
          instances.value = []
        }
      }

      return instances
    },
    allSettings() {
      return this.$store.getters['global/getSettings']
    },
    columnsInstances() {
      //console.log('current tab :', this.currentTab)
      //console.log('current tab 2 :', this.currentTab2)
      if (this.currentTab === 0) {
        return [
          { text: this.$t('channels.label'), value: 'label' },
          { text: `${this.$t('channels.phoneNumber')}`, value: 'phone_number' },
          { text: 'Status', value: 'status', sortable: false },
          // { text: 'Sync Contact', value: 'sync_contact' },
          { text: this.$t('channels.actions'), value: 'actions', sortable: false },
        ]
      } else if (this.currentTab === 5) {
        return [
          { text: 'Page Name', value: 'label' },
          { text: 'Page Id', value: 'id_page', sortable: false },
          { text: 'Status', value: 'status', sortable: false },
          { text: this.$t('channels.actions'), value: 'actions', sortable: false },
        ]
      } else if (this.currentTab === 2) {
        return [
          { text: 'Group Name', value: 'group_name' },
          // { text: `${this.$t('channels.phoneNumber')}`, value: 'phone_number' },
          { text: 'Profile Name', value: 'profile_name' },
          { text: 'Profile Picture', value: 'profile_picture' },
          // { text: 'Sync Contact', value: 'sync_contact' },
          { text: 'Number List', value: 'expand.instances' },
          { text: this.$t('channels.actions'), value: 'actions' },
        ]
      } else if (this.currentTab === 3) {
        return [
          { text: this.$t('channels.label'), value: 'label' },
          { text: 'Business Name', value: 'business_name' },
          { text: this.$t('channels.actions'), value: 'actions', sortable: false },
        ]
      } else if (this.currentTab === 4) {
        return [
          { text: 'Name', value: 'label' },
          { text: 'Username', value: 'instagram_username' },
          { text: 'Connected Page Id', value: 'connected_page', sortable: false },
          // { text: 'Sync Contact', value: 'sync_contact' },
          { text: 'Status', value: 'status', sortable: false },
          { text: this.$t('channels.actions'), value: 'actions', sortable: false },
        ]
      } else if (this.currentTab === 1) {
        return [
          { text: 'Name', value: 'label' },
          { text: `Inbox`, value: 'type' },
          { text: 'Status', value: 'status', sortable: false },
          { text: this.$t('channels.actions'), value: 'actions', sortable: false },
        ]
      } else {
        return [
          { text: this.$t('channels.label'), value: 'label' },
          { text: `Shop ID`, value: 'phone_number' },
          { text: 'Status', value: 'status', sortable: false },
          // { text: 'Sync Contact', value: 'sync_contact' },
          { text: this.$t('channels.actions'), value: 'actions', sortable: false },
        ]
      }
    },
    // instances() {
    //   const inst = this.$store.getters['auth/getListInstance']
    //   if (inst) {
    //     return inst
    //   } else {
    //     return []
    //   }
    // },
    listInstancesFilter() {
      this.loadingTable = true
      if (this.instances) {
        if (this.instances.value && this.instances.value.length) {
          if (this.currentTab === 0) {
            return this.instances.value.filter(x => {
              if (!x.inbox_id && x.broadcast_group === false) {
                if (this.user.limited_channel) {
                  this.loadingTable = false
                  return (
                    this.user.enabled_channel &&
                    this.user.enabled_channel.find(y => y === x._id || y === x.phone_number)
                  )
                } else if (
                  !this.user.limited_channel &&
                  !x.hasOwnProperty('clientSecret') &&
                  !x.hasOwnProperty('id_page') &&
                  !x.hasOwnProperty('id_instagram') &&
                  !x.hasOwnProperty('username') &&
                  !x.hasOwnProperty('phone_number_id') &&
                  !x.hasOwnProperty('profileKey') &&
                  x.broadcast_group === false
                ) {
                  this.loadingTable = false
                  return true
                }
              }
              this.loadingTable = false
              return false
            })
          } else if (this.currentTab === 1) {
            return this.instances.value.filter(x => {
              if (x.inbox_id) {
                if (this.user.limited_channel) {
                  this.loadingTable = false
                  return (
                    this.user.enabled_channel &&
                    this.user.enabled_channel.find(y => y === x._id || y === x.phone_number)
                  )
                }
                this.loadingTable = false
                return true
              }
              this.loadingTable = false
            })
          }
          // else if (this.currentTab === 1) {
          //   return this.instances.value.filter(x => {
          //     if (x.hasOwnProperty('clientSecret')) {
          //       return true
          //     }
          //     return false
          //   })
          // }
          else if (this.currentTab === 5) {
            return this.instances.value.filter(x => {
              if (x.hasOwnProperty('id_page')) {
                return true
              }
              return false
            })
          } else if (this.currentTab === 2) {
            return this.instances.value.filter(x => {
              if (x.hasOwnProperty('username')) {
                return true
              }
              return false
            })
          } else if (this.currentTab === 3) {
            return this.instances.value.filter(x => {
              if (x.hasOwnProperty('phone_number_id')) {
                return true
              }
              return false
            })
          } else if (this.currentTab === 4) {
            return this.instances.value.filter(x => {
              if (x.hasOwnProperty('id_instagram')) {
                return true
              }
            })
          } else if (this.currentTab === 1) {
            for (const el of this.instances.value) {
              if (el.hasOwnProperty('profileKey')) {
                return el.activeSocialAccounts
              }
            }
          }
        }
      }
      this.loadingTable = false
      return []
    },
    listInstagramInstance() {
      if (this.instances) {
        //console.log(this.instances.value)
        const instagramInstances = this.instances.value.filter(function (item) {
          if (item.instagram_business_account) {
            return true
          }
        })
        //console.log(instagramInstances, '<<<')
        return instagramInstances
      }
      return []
    },
    workspaces() {
      return this.$store.getters['auth/getListWorkspaces']
    },
    getWorkspaceName() {
      var data = this.workspaces.find(workspace => workspace.sub_id === this.user.sub_id)

      if (data) {
        return data.workspace_name ? data.workspace_name : 'Workspace ' + data.sub_id
      }

      return ''
    },
    selectedWorkspace() {
      return this.$store.getters['auth/getSelectedWorkspace']
    },
    dataWorkspaces() {
      return this.$store.getters['auth/getListWorkspaces']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    syncSettingOptions() {
      return [
        {
          label: this.$t('channels.syncSettingOptionOne'),
          value: 0,
        },
        {
          label: this.$t('channels.syncSettingOptionTwo'),
          value: 1,
        },
      ]
    },
    howToUseOne() {
      return this.$t('channels.howToUseOne')
    },
    howToUseTwo() {
      return this.$t('channels.howToUseTwo')
    },
    howToUseThree() {
      return this.$t('channels.howToUseThree')
    },
    howToUseFour() {
      return this.$t('channels.howToUseFour')
    },
    howToUseFive() {
      return this.$t('channels.howToUseFive')
    },
    howToUseSix() {
      return this.$t('channels.howToUseSix')
    },
    howToUseOneConnect1() {
      return this.$t('channels.howToUseOneConnect1')
    },
    howToUseTwoConnect1() {
      return this.$t('channels.howToUseTwoConnect1')
    },
    howToUseThreeConnect1() {
      return this.$t('channels.howToUseThreeConnect1')
    },
    howToUseFourConnect1() {
      return this.$t('channels.howToUseFourConnect1')
    },
    howToUseOneConnect2() {
      return this.$t('channels.howToUseOneConnect2')
    },
    howToUseTwoConnect2() {
      return this.$t('channels.howToUseTwoConnect2')
    },
    howToUseThreeConnect2() {
      return this.$t('channels.howToUseThreeConnect2')
    },
    howToUseFourConnect2() {
      return this.$t('channels.howToUseFourConnect2')
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
  },
}
</script>

<style scoped>
.theme--dark.v-card > .v-card__text,
.theme--dark.v-card > .v-card__subtitle {
  margin-bottom: 10px !important;
  margin-top: 10px !important;
}
.bottom-action {
  display: none;
}

/* .loading-iframe {
  background: url(https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif) center center no-repeat;
} */

.loading-box {
  width: 276px;
  height: 277px;
}

@media (max-width: 536px) {
  .hide-buton {
    display: none;
  }
  .bottom-action {
    display: inherit;
  }
}

.state-circle {
  display: inline-block;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 6px;
  transition: 0.3s;
}

.state-label {
  display: inline-block;
}

.instagram-container {
  display: flex;
  flex-direction: column;
}

.instagram-row-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: center;
  border-bottom: 1px solid #000;
  margin-bottom: 10px;
}

@media (max-width: 536px) {
  .hero-landing {
    justify-content: center;
    text-align: center;
  }
  .mobile-tag {
    display: block;
  }
  .bottom-action {
    display: inherit;
  }
  .hide-lottie {
    display: none;
  }
  /* .hero-subtitle {
    width: 200px;
  } */
}
</style>
